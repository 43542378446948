export const commentTabsKeys = {
  TOTAL: 'total',
  POTENTIAL_HIDE: 'potentialToHide',
  HIDDEN: 'hidden',
};

export const tabsKeysToTabDisplayName = {
  [commentTabsKeys.TOTAL]: 'Total Comments',
  [commentTabsKeys.POTENTIAL_HIDE]: 'Potential to Hide',
  [commentTabsKeys.HIDDEN]: 'Hidden Comments',
};

export const dashboardTimeFilterKeys = {
  MODERATION: 'moderationTime',
  ENGAGEMENTS: 'engagementsTime',
  SENTIMENT: 'sentimentTime',
  INTENTS: 'intentsTime',
  VIEWER: 'viewerTime',
};
export const pageFilterKeys = {
  FILTERS: 'filters',
};
export const pageKeysForFilter = {
  INBOX: 'inbox',
  COMMENTS: 'comments',
  BROADCASTS: 'broadcasts',
  PROFILE_SETTINGS: 'profileSettings',
  ACCOUNT_SETTINGS: 'accountSettings',
  SOCIAL_MEDIA_ACCOUNTS: 'socialMediaAccounts',
  HOME: 'home',
  RESOURCES: 'resources',
  AUDIENCE: 'audience',
  SENTIMENT_ANALYSIS: 'sentimentAnalysis',
  REPORTS: 'reports',
  SEGMENTS: 'segments',
};
export const CommentsFilterKeysInsideFilter = {
  TIME: 'time',
  TAB_NAME: 'tabName',
  TAG: 'tag',
  POST: 'posts',
  PLATFORM_USER_ID: 'platformUserId',
  PLATFORM: 'platform',
  SELECTED_MESSAGE_ID: 'selectedMessageId',
  VIEW_USER_DETAILS: 'viewUserDetails',
  VIEW_MANAGE_LABEL: 'viewManageLabels',
  LAY_OUT: 'layout',
  CURRENT_COMPONENT: 'currentcomponent',
  PREVIOUS_COMPONENT: 'previouscomponent',
  MESSAGE_DETAILS: 'messageDetails',
  INBOX: 'inbox',
  USERPROFILE_PIC: 'useprofilepicture',
  CUSTOM_FILTER: 'customFilter',
};
export const viewerTabs = {
  COMMENT_MODERATION: 'commentModeration',
  MENTIONS_MODERATION: 'mentionsModeration',
  ENGAGEMENTS: 'engagements',
};
export const dashboardTabConstants = {
  COMMENTS: 'comments',
  MENTIONS: 'mentions',
  POST_MENTIONS: 'postMentions',
};

export const dashboardTabsToSubURL = {
  [dashboardTabConstants.COMMENTS]: dashboardTabConstants.COMMENTS,
  [dashboardTabConstants.MENTIONS]: dashboardTabConstants.MENTIONS,
  [dashboardTabConstants.POST_MENTIONS]: dashboardTabConstants.POST_MENTIONS,
};

export const dashboardTabs = {
  [dashboardTabConstants.COMMENTS]: {
    label: 'Comments',
    subURL: dashboardTabsToSubURL[dashboardTabConstants.COMMENTS],
  },
  [dashboardTabConstants.MENTIONS]: {
    label: 'Comment Mentions',
    subURL: dashboardTabsToSubURL[dashboardTabConstants.MENTIONS],
  },
  [dashboardTabConstants.POST_MENTIONS]: {
    label: 'Post Mentions',
    subURL: dashboardTabsToSubURL[dashboardTabConstants.POST_MENTIONS],
  },
};

export const CommentsModeConstants = {
  MODERATION: 'moderation',
  ENGAGEMENTS: 'engagements',
};

export const CommentsMode = {
  [CommentsModeConstants.MODERATION]: {
    label: 'Moderation',
    subURL: CommentsModeConstants.MODERATION,
  },
  [CommentsModeConstants.ENGAGEMENTS]: {
    label: 'Engagements',
    subURL: CommentsModeConstants.ENGAGEMENTS,
  },
};

export enum CommentsTabEnums {
  COMMENTS = 'comments',
  MENTIONS = 'mentions',
  SENTIMENTANALYSIS = 'sentimentAnalysis',
}

export const maxLimits = {
  COMMENTS: 10000,
  POSTS: 10000,
  TAGFILTER: 1000,
};

export const showViewModal = 'showViewModal';

export const commentTabNames = {
  COMMENTS: 'Comments',
  MENTIONS: 'Mentions',
  SENTIMENTANALYSIS: 'Sentiment Analysis',
};
