// import { setTemplatesLoaded } from '../../../pages/templates/actions';
//import { storeSegment } from '../../../pages/segments/actions';
import { toString } from 'lodash';
import { appendVariableValue, storeSegments } from '.';
import { notAvailable } from '../../../constants';
import { storeSegment, storeSegmentsPagination, storeSegmentUserCount } from '../../../pages/segments/actions';
import {
  FetchPaginatedSegmentsApiResponse,
  FetchSegmentApiResponse,
  FetchSegmentsApiResponse,
  Segment,
  Segments,
  segmentUserCountAPIRequest,
  segmentUserCountAPIResponse,
} from '../../../pages/segments/segment.types';
import { AsyncThunkDispatch, RootState, Thunk } from '../../../types';
import { delete as restDelete, get, post, put } from '../../api-calls/api-client';
import { showErrorModal } from '../../utils';
import transformations from '../transformer/segments';
import { getObjectToParams } from '../utils';

export const fetchSegment = (segmentId: string, refreshUserCount: boolean = false) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<Segment | null> => {
    let response: { data: FetchSegmentApiResponse; status: number } | null = null;

    const {
      auth: { authToken },
    } = getState();
    try {
      response = await get(`/segments/${segmentId}${refreshUserCount ? '?refreshUserCount=true' : ''}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      const transformedSegment = transformations.fetchSegmentApiToUi(response.data);
      dispatch(storeSegment(transformedSegment));
      dispatch(storeSegmentUserCount(toString(transformedSegment.totalContacts)));
      return transformedSegment;
    }
    return null;
  };
};

export const fetchSegments = (refreshAllUserCounts: boolean = false, pagination?: any) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<Segments | null> => {
    let response: { data: FetchPaginatedSegmentsApiResponse; status: number } | null = null;
    const {
      auth: { authToken },
      pageUIState,
      entities,
    } = getState();
    const platformBotId = pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId]?.botId : '';
    const botPlatform = entities.platformBots ? entities.platformBots[platformBotId]?.platform : '';
    const paginationQueryParams = pagination && getObjectToParams(pagination);
    const url = `/segments?botId=${botId}${botPlatform ? `&platform=${botPlatform}` : ''}${refreshAllUserCounts ? '&refreshUserCount=true' : ''}`;
    const paginationUrl = `segments/paginated?botId=${botId}${botPlatform ? `&platform=${botPlatform}` : ''}${refreshAllUserCounts ? '&refreshUserCount=true' : ''}${
      paginationQueryParams ? `&${paginationQueryParams}` : ''
    }`;
    try {
      response = await get(pagination ? paginationUrl : url, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      let segmentRecords: any = response.data;
      if (pagination) {
        const { records, ...paginationInfo } = response.data;
        segmentRecords = records;
        dispatch(storeSegmentsPagination(paginationInfo));
      }
      const segments = transformations.fetchSegmentsApiToUi(segmentRecords);
      dispatch(storeSegments(segments));
    }
    return null;
  };
};

export const getUserSegments = (platformUser: string, onFetch?: (segments: Segments) => void) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<Segments | null> => {
    let response: { data: FetchSegmentsApiResponse; status: number } | null = null;
    const {
      auth: { authToken },
      pageUIState,
      entities,
    } = getState();
    const platformBotId = pageUIState.selectedPlatformBot || '';
    const platformUserId = platformUser;
    const botId = entities.platformBots ? entities.platformBots[platformBotId]?.botId : '';
    const platform = entities.platformBots ? entities.platformBots[platformBotId].platform : '';
    const segmentPayload = { platform, botId, platformBotId, platformUserId };
    try {
      response = await post(`/segments/getUserSegments`, segmentPayload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
      onFetch?.({});
    }
    if (response && response.status === 200) {
      const segments = transformations.fetchSegmentsApiToUi(response.data);
      onFetch?.(segments);
      // dispatch(storeSegments(segments));
    }
    return null;
  };
};
export const createSegment = (segment: { [key: string]: unknown }, done?: (id?: string) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth, pageUIState, entities } = getState();
    const { authToken } = auth;
    const platformBotId = pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId]?.botId : '';
    const botPlatform = entities.platformBots ? entities.platformBots[platformBotId]?.platform : '';
    const segmentPayload = { ...segment, botId };
    let response = null;
    try {
      response = await post(`/segments${botPlatform ? `?platform=${botPlatform}` : ''}`, segmentPayload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      done && done(response?.data?.id);
    }
  };
};

export const updateSegment = (segment: { [key: string]: any }, done?: (id?: string) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth, entities, pageUIState } = getState();
    const { authToken } = auth;
    const platformBotId = pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId]?.botId : '';
    const botPlatform = entities.platformBots ? entities.platformBots[platformBotId]?.platform : '';
    const segmentPayload = { ...segment, botId };
    let response = null;
    try {
      response = await put(`/segments${botPlatform ? `?platform=${botPlatform}` : ''}`, segmentPayload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      done && done(segment?.id);
    }
  };
};

export const deleteSegment = (id: string): Thunk<boolean> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<boolean> => {
    let response: { status: number } | null = null;
    const {
      auth: { authToken },
    } = getState();
    try {
      response = await restDelete(`/segments/${id}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      return true;
    }
    return false;
  };
};

export const fetchSegmentCount = (
  segmentCondition: segmentUserCountAPIRequest,
  doNotShowLoader?: boolean | undefined,
  storeInRedux: boolean = true,
  transformSegmentConditions: boolean = false,
): Thunk<number | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<number | null> => {
    let response: { status: number; data: segmentUserCountAPIResponse } | null = null;
    const { auth, entities, pageUIState } = getState();
    const { authToken } = auth;
    const payload = segmentCondition;
    const platformBotId = pageUIState.selectedPlatformBot || '';
    const botPlatform = entities.platformBots ? entities.platformBots[platformBotId]?.platform : '';
    if (botPlatform) {
      payload.platform = botPlatform;
    }
    payload?.conditions && (payload.conditions = transformations.segmentConditionUiToApi(payload?.conditions, transformSegmentConditions));
    try {
      response = await post(`segments/count`, payload, authToken as string, doNotShowLoader);
    } catch (error: any) {
      if (error.response.status !== 400) {
        showErrorModal(error, dispatch);
      }
    }
    if (response && response.status === 200) {
      const data = response.data;
      storeInRedux && dispatch(storeSegmentUserCount(toString(data.count)));
      return data.count;
    }
    storeInRedux && dispatch(storeSegmentUserCount(toString(notAvailable)));
    return null;
  };
};
export const fetchVariableValues = (botId: string) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<any> => {
    let response: { data: any; status: number } | null = null;
    const transformedResponse: any = {};
    const state = getState();
    const selectedPlatformBot = state.pageUIState.selectedPlatformBot;
    const platform = selectedPlatformBot && state.entities.platformBots?.[selectedPlatformBot];
    const varMacroList = ['profile.timezone', 'profile.gender', 'profile.location.city', 'profile.location.countryCode', 'profile.location.country', 'profile.location.state']; //label, locale?,
    const aggregations: any = [];
    varMacroList?.forEach((macroValue?: string) =>
      aggregations.push({
        aggregationField: macroValue,
      }),
    );
    const payload = {
      botIds: [botId],
      filters: [],
      platform,
      aggregations,
    };
    try {
      response = await post(`segments/aggregations`, payload, state.auth.authToken as string);
      Object.keys(response?.data?.count)?.forEach((varMacro: string) => {
        const allValues = response?.data?.count?.[varMacro]?.buckets;
        transformedResponse[varMacro] = allValues;
      });
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      dispatch(appendVariableValue(transformedResponse));
      return {};
    }
    return null;
  };
};
