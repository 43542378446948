/* eslint-disable max-lines */
import { platforms, postType as postTypes } from '../../../../../../../common/entities/entities.constants';
import { actions } from '../../../../../../../config/platform-action-map';
import { PrivateReplyActionType } from '../../../../../../automation/constants';
// import { commentViewerTabs } from '../../../comments-viewer.constants';
import { Templates } from '../../../../../../templates/templates.types';
// import { AmplifyGeneratedEngagement } from '../../../../../../common/tag/tag.constants';
import { TagGroup } from '../../../../../../common/tag/tag.types';
import { engagementActions, engagementActionTypes, engagementTypes } from '../comment.constants';
import { Comment, CommentActions, ReplyObjAPI, ReplyInfo, ReplyType, RawComment as CommentType, RawComment, EngagedActionLabelMap } from '../comment.types';
import variables from '../../../../../../../common/scss/variables.module.scss';
import moment from 'moment-timezone';
import { getCommentInfoReplyMessage } from '../../../utils';
import { Comments, PlatformBot } from '../../../../../../../common/entities/entities.types';
import { AmplifyGeneratedEngagement } from '../../../../../../common/tag/tag.constants';

export const colorForSentimentScore = (sentiment: string) => {
  const defaultColor = variables.sentimentNeutral;
  if (!sentiment) return defaultColor;
  if (sentiment && sentiment.toLowerCase().includes('positive')) return variables.sentimentPositive;
  if (sentiment && sentiment.toLowerCase().includes('negative')) return variables.sentimentNegative;
  return defaultColor;
};
export const colorForSentiment = (sentiment: string) => {
  const defaultColor = variables.sentimentNeutral;
  if (!sentiment) return defaultColor;
  if (sentiment && sentiment.toLowerCase().includes('positive')) return variables.sentimentPositive;
  if (sentiment && sentiment.toLowerCase().includes('negative')) return variables.sentimentNegative;
  if (sentiment && sentiment.toLowerCase().includes('mixed')) return variables.sentimentMixed;
  return defaultColor;
};

export const colorForPostType = (postType: string) => {
  switch (postType) {
    case postTypes.PAID:
      return variables.paidPostType;
    default:
      return variables.organicPostType;
  }
};

export const getCommentLinkValidity = (platform: string, comment: Comment) => {
  switch (platform) {
    case platforms.FACEBOOK:
      return comment.commentId && comment.userComment.postId ? true : false;
    case platforms.INSTAGRAM:
      return comment.commentId && comment.userComment.postShortId ? true : false;
    case platforms.TWITTER:
      return true;
    case platforms.YOUTUBE:
      return comment.userComment && comment.userComment.postId ? true : false;
  }
};
export const getReplyLinkValidity = (platform: string, comment: Comment, replyId?: string) => {
  switch (platform) {
    case platforms.FACEBOOK:
    case platforms.TIKTOK:
      return comment.commentId && comment.userComment.postId && replyId ? true : false;
    case platforms.INSTAGRAM:
    case platforms.THREADS:
      return comment.commentId && comment.userComment.postShortId && replyId ? true : false;
    case platforms.TWITTER:
      return replyId ? true : false;
    case platforms.YOUTUBE:
      return comment.commentId && comment.userComment.postId && replyId ? true : false;
    default:
      return replyId && comment.commentId;
  }
};

export const getCommentOrReplyLink = (platformBotObj: PlatformBot | undefined, comment: Comment, commentReplyId?: string) => {
  const platform = platformBotObj?.platformAsInSystemSettings || '';
  switch (platform) {
    case platforms.FACEBOOK:
      return getCommentOrReplyLinkFb(comment.commentId, comment.userComment.postId, platformBotObj, commentReplyId);
    case platforms.INSTAGRAM:
      //IG replies also become comments. hence check parentId. then commentId= parentId, replyId= commentId (an IG comment with parentId is actually a reply)
      return getCommentOrReplyInstaLink(comment.parentId || comment.commentId, comment.userComment.postShortId, !comment.parentId ? commentReplyId : comment.commentId);
    case platforms.TWITTER:
      return getCommentOrReplyTwitterLink(comment.commentId, commentReplyId);
    case platforms.YOUTUBE:
      return getCommentOrReplyYoutubeLink(comment, commentReplyId);
    default:
      return '';
  }
};
export const getCommentOrReplyLinkFb = (commentId: string, postId: string, platformBotObj: PlatformBot | undefined, replyId?: string) => {
  const pageUserNameOrId = platformBotObj?.config?.username || platformBotObj?.platformBotId || (postId && postId.split('_')[0]);
  if (replyId) {
    // Reply Link
    return `https://www.facebook.com/${pageUserNameOrId}/posts/${postId && postId.split('_')[1]}?comment_id=${commentId && commentId.split('_')[1]}&reply_comment_Id=${replyId && replyId.split('_')[1]
      }`;
  }
  // Comment Link
  return `https://www.facebook.com/${pageUserNameOrId}/posts/${postId && postId.split('_')[1]}?comment_id=${commentId && commentId.split('_')[1]}`;
};
export const getCommentOrReplyInstaLink = (commentId: string, postShortId: string, replyId?: string) => {
  if (replyId) {
    // Reply Link
    return `https://www.instagram.com/p/${postShortId}/c/${commentId}/r/${replyId}`;
  }
  // Comment Link
  return `https://www.instagram.com/p/${postShortId}/c/${commentId}/`;
};
export const getCommentOrReplyTwitterLink = (commentId: string, replyId?: string) => {
  if (replyId) {
    // Reply Link
    return `https://twitter.com/i/web/status/${replyId}`;
  }
  // Comment Link
  return `https://twitter.com/i/web/status/${commentId}`;
};
export const getCommentOrReplyYoutubeLink = (comment: Comment, replyId?: string) => {
  if (replyId) {
    // Reply Link
    return `https://www.youtube.com/watch?v=${comment.userComment.postId}&lc=${replyId}`;
  }
  // Comment Link
  return `https://www.youtube.com/watch?v=${comment.userComment.postId}&lc=${comment.commentId}`;
};

export const getCommentActionDisableConfig = (actions: CommentActions = {}) => {
  const actionsDisableConfig: { [key: string]: boolean } = {};
  Object.keys(actions).forEach((action: string) => {
    switch (action) {
      case engagementActionTypes['hide'][engagementTypes.MANUAL]:
      case engagementActionTypes['hide'][engagementTypes.AUTOMATED]:
      case engagementActionTypes['hide'][engagementTypes.EXTERNAL]:
        actionsDisableConfig[PrivateReplyActionType.HIDE] = true;
        break;
      case engagementActionTypes['like'][engagementTypes.MANUAL]:
      case engagementActionTypes['like'][engagementTypes.AUTOMATED]:
      case engagementActionTypes['like'][engagementTypes.EXTERNAL]:
        actionsDisableConfig[PrivateReplyActionType.LIKE] = true;
        break;
      case engagementActionTypes['block'][engagementTypes.MANUAL]:
      case engagementActionTypes['block'][engagementTypes.AUTOMATED]:
        actionsDisableConfig[PrivateReplyActionType.BLOCK] = true;
        actionsDisableConfig[PrivateReplyActionType.DELETE] = true;
        break;
      case engagementActionTypes['delete'][engagementTypes.MANUAL]:
      case engagementActionTypes['delete'][engagementTypes.AUTOMATED]:
      case engagementActionTypes['delete'][engagementTypes.EXTERNAL]:
      case 'externalUserCommentDelete':
        actionsDisableConfig[PrivateReplyActionType.DELETE] = true;
        break;
      case engagementActionTypes['review'][engagementTypes.MANUAL]:
      case engagementActionTypes['review'][engagementTypes.AUTOMATED]:
        actionsDisableConfig[PrivateReplyActionType.MARKASREVIEWED] = true;
        break;
      case engagementActionTypes['privateReply'][engagementTypes.MANUAL]:
        actionsDisableConfig[PrivateReplyActionType.PRIVATE] = true;
        break;
    }
  });
  return actionsDisableConfig;
};

export const getBulkCommentActionDisableConfig = (comments: Comments, selectedComment: CommentType[] = []) => {
  const bulkActionsDisableConfig: { [key: string]: boolean } = {};
  Object.values(comments).forEach((comment: Comment) => {
    // eslint-disable-next-line @typescript-eslint/typedef
    selectedComment.forEach((usercomment) => {
      if (usercomment.commentId === comment.commentId) {
        Object.keys(comment.actions).forEach((action: string) => {
          switch (action) {
            case engagementActionTypes['hide'][engagementTypes.MANUAL]:
            case engagementActionTypes['hide'][engagementTypes.AUTOMATED]:
            case engagementActionTypes['hide'][engagementTypes.EXTERNAL]:
              bulkActionsDisableConfig[PrivateReplyActionType.HIDE] = true;
              break;
            case engagementActionTypes['like'][engagementTypes.MANUAL]:
            case engagementActionTypes['like'][engagementTypes.AUTOMATED]:
            case engagementActionTypes['like'][engagementTypes.EXTERNAL]:
              bulkActionsDisableConfig[PrivateReplyActionType.LIKE] = true;
              break;
            case engagementActionTypes['block'][engagementTypes.MANUAL]:
            case engagementActionTypes['block'][engagementTypes.AUTOMATED]:
            case engagementActionTypes['delete'][engagementTypes.MANUAL]:
            case engagementActionTypes['delete'][engagementTypes.AUTOMATED]:
            case engagementActionTypes['delete'][engagementTypes.EXTERNAL]:
            case 'externalUserCommentDelete':
              bulkActionsDisableConfig['all'] = true;
              break;
            case engagementActionTypes['review'][engagementTypes.MANUAL]:
            case engagementActionTypes['review'][engagementTypes.AUTOMATED]:
              bulkActionsDisableConfig[PrivateReplyActionType.MARKASREVIEWED] = true;
              break;
            case engagementActionTypes['privateReply'][engagementTypes.MANUAL]:
              bulkActionsDisableConfig[PrivateReplyActionType.PRIVATE] = true;
              break;
          }
        });
      }
    });
  });
  return bulkActionsDisableConfig;
};

export const getCommentAutomatedActionConfig = (actions: CommentActions = {}, filterEngagementTypes: string[]) => {
  const automatedActionsVisibleConfig: { [key: string]: boolean } = {};
  Object.keys(actions).forEach((action: string) => {
    filterEngagementTypes.forEach((engagementType: string) => {
      switch (engagementType) {
        case AmplifyGeneratedEngagement:
          switch (action) {
            case engagementActionTypes['hide'][engagementTypes.AUTOMATED]:
            case engagementActionTypes['like'][engagementTypes.AUTOMATED]:
            case engagementActionTypes['block'][engagementTypes.AUTOMATED]:
            case engagementActionTypes['delete'][engagementTypes.AUTOMATED]:
            case engagementActionTypes['review'][engagementTypes.AUTOMATED]:
            case 'automatedMute':
            case engagementActionTypes['privateReply'][engagementTypes.AUTOMATED]:
            case engagementActionTypes['publicReply'][engagementTypes.AUTOMATED]:
              automatedActionsVisibleConfig[engagementTypes.AUTOMATED] = true;
              break;
          }
          break;
        case engagementTypes.MANUAL:
          switch (action) {
            case engagementActionTypes['hide'][engagementTypes.MANUAL]:
            case engagementActionTypes['like'][engagementTypes.MANUAL]:
            case engagementActionTypes['block'][engagementTypes.MANUAL]:
            case engagementActionTypes['delete'][engagementTypes.MANUAL]:
            case engagementActionTypes['review'][engagementTypes.MANUAL]:
            case engagementActionTypes['privateReply'][engagementTypes.MANUAL]:
            case engagementActionTypes['publicReply'][engagementTypes.MANUAL]:
              automatedActionsVisibleConfig[engagementTypes.MANUAL] = true;
              break;
          }
          break;
        case engagementTypes.EXTERNAL:
          switch (action) {
            case engagementActionTypes['hide'][engagementTypes.EXTERNAL]:
            case engagementActionTypes['like'][engagementTypes.EXTERNAL]:
            case engagementActionTypes['delete'][engagementTypes.EXTERNAL]:
            case engagementActionTypes['privateReply'][engagementTypes.EXTERNAL]:
            case engagementActionTypes['publicReply'][engagementTypes.EXTERNAL]:
              automatedActionsVisibleConfig[engagementTypes.EXTERNAL] = true;
              break;
          }
      }
    });
  });
  return automatedActionsVisibleConfig;
};
export const getAmplifyEngagementTypeNames = (engagedTag: TagGroup): string[] | null => {
  const amplifyEngaged = engagedTag?.[AmplifyGeneratedEngagement]?.tabNamesForAmplifyEngaged;
  if (amplifyEngaged && Object.keys(amplifyEngaged).length > 0) {
    const keys = Object.keys(amplifyEngaged).map((key: string) => {
      return amplifyEngaged[key];
    });
    return keys;
  }
  return null;
};

export const getAmplifyEngagementType = (engagedTag: TagGroup): string | null => {
  const amplifyEngaged = engagedTag?.[AmplifyGeneratedEngagement]?.tabNamesForAmplifyEngaged;
  if (amplifyEngaged && Object.keys(amplifyEngaged).length > 0) {
    return amplifyEngaged[Object.keys(amplifyEngaged)[0]];
  }
  return null;
};
export const getSupportedActions = (actionsSupported: string[]) => {
  const updatedSupportedActions = new Set(actionsSupported);
  // updatedSupportedActions.delete(actions.PRIVATE_REPLY);
  // if (actionHasBeenTaken) {
  //   updatedSupportedActions.delete(actions.REVIEW);
  // }
  return updatedSupportedActions;
};
export const getSupportedActionsInOrder = (actionsSupported: Set<string>, removeHide?: boolean) => {
  const supportedActionsArray = [];
  if (actionsSupported.has(actions.PUBLIC_REPLY)) supportedActionsArray.push(actions.PUBLIC_REPLY);
  if (actionsSupported.has(actions.REVIEW)) supportedActionsArray.push(actions.REVIEW);
  if (actionsSupported.has(actions.HIDE) && !removeHide) supportedActionsArray.push(actions.HIDE);
  if (actionsSupported.has(actions.BLOCK)) supportedActionsArray.push(actions.BLOCK);
  if (actionsSupported.has(actions.LIKE)) supportedActionsArray.push(actions.LIKE);
  if (actionsSupported.has(actions.PRIVATE_REPLY)) supportedActionsArray.push(actions.PRIVATE_REPLY);
  if (actionsSupported.has(actions.DELETE)) supportedActionsArray.push(actions.DELETE);

  return supportedActionsArray;
};

export const getCommentReplyInfo = (
  actions: CommentActions,
  commentUserId: string,
  username: string,
  platformUsername: string = '',
  templates: Templates,
  platform: string,
  tabType?: string,
) => {
  // const replyObject: ReplyObjAPI = actions.publicReply || actions.automatedPublicReply || actions.externalPublicReply || null;
  const replyObjects: { replyObject: ReplyObjAPI; replyType: string }[] =
    tabType === engagementActions.MANUAL_PRIVATE_REPLY
      ? [{ replyObject: actions.privateReply, replyType: engagementActions.MANUAL_PRIVATE_REPLY }]
      : [
        { replyObject: actions.publicReply, replyType: engagementActions.MANUAL_PUBLIC_REPLY },
        { replyObject: actions.automatedPublicReply, replyType: engagementActions.AUTOMATED_PUBLIC_REPLY },
        { replyObject: actions.externalPublicReply, replyType: engagementActions.EXTERNAL_PUBLIC_REPLY },
      ];
  let allReplies: ReplyType[] = [];

  replyObjects.forEach(({ replyObject, replyType }: { replyObject: ReplyObjAPI; replyType: string }) => {
    if (!replyObject) {
      return;
    }
    if (replyObject && replyObject.replies) {
      const replies =
        replyObject.replies.map((replyObject: ReplyInfo) => createCommentReplyInfo(replyObject, commentUserId, username, platformUsername, templates, platform, replyType)) || [];
      allReplies = [...allReplies, ...replies];
      return;
    }
    allReplies = [...allReplies, createCommentReplyInfo(replyObject!, commentUserId, username, platformUsername, templates, platform, replyType)];
    return;
  });

  return getSortedReplies(allReplies);
};

const getSortedReplies = (replies: ReplyType[]) => {
  return replies.sort((r1: ReplyType, r2: ReplyType) => {
    return moment(r1.commentReplyTime).isAfter(r2.commentReplyTime) ? -1 : 1;
  });
};

const createCommentReplyInfo = (
  replyObject: ReplyInfo,
  commentUserId: string,
  username: string,
  platformUsername: string = '',
  templates: Templates,
  platform: string,
  replyType?: string,
) => {
  let commentReplyMessage = '';
  let commentReplyTime = '';
  let replyId = '';

  let prefix = '';
  const templateKey = replyObject?.replyMessage && getTemplateKeyFromMessage(replyObject.replyMessage, platform, commentUserId);
  if (templateKey && templates?.[templateKey]) {
    prefix = `${templates[templateKey].name}: `;
  }
  const usernameDisplay = platform === platforms.TWITTER ? `@${platformUsername || username}` : username;
  if (replyObject) {
    const pattern1 = '{{message.userMention}}';
    const pattern2 = `\\@\\[${commentUserId}]`;
    let regex;
    try {
      regex = new RegExp(`${pattern1}|${pattern2}`, 'g');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('invalid pattern commentUserId', pattern2);
    }
    const messageContent = (regex && replyObject.replyMessage?.replaceAll(regex, usernameDisplay)) || '';
    commentReplyMessage = messageContent ? getCommentInfoReplyMessage(messageContent) : messageContent;
    commentReplyMessage = commentReplyMessage ? (prefix ? `${prefix}"${commentReplyMessage}"` : commentReplyMessage) : 'Attachment was sent';
    commentReplyTime = replyObject.date;
    replyId = replyObject.replyId || '';
  }
  return { commentReplyMessage, commentReplyTime, replyId, user: replyObject?.user, replyType };
};

const getTemplateKeyFromMessage = (message: string, platform: string, commentUserId: string) => {
  let key = message;
  if (platform === platforms.TWITTER && message.startsWith('{{message.userMention}} ')) {
    key = key.replace('{{message.userMention}} ', '');
  }
  if (message.includes(`@[${commentUserId}]`)) {
    key = key.replaceAll(`@[${commentUserId}]`, '{{message.userMention}}');
  }
  return key;
};

export const getErrorResponse = (comments: CommentType[], response: any) => {
  const userCommentsError: any = [];
  const selectedCommentsError: RawComment[] = [];
  comments.forEach((comment: CommentType) => {
    Object.values(response.data).forEach((error: any) => {
      Object.keys(error).forEach((key: string) => {
        if (key === comment.commentId) {
          userCommentsError.push({
            commentId: comment.commentId,
            message: response.data.errors[key],
          });
          selectedCommentsError.push(comment);
        }
      });
    });
  });

  return { userCommentsError, selectedCommentsError };
};

export const getToolTipForActionTakenBy = (checkForAction: keyof typeof engagementActionTypes, labels: EngagedActionLabelMap, actions?: CommentActions) => {
  let showToolTip: boolean = false;
  let tooltipText: string = '';
  const engagementTypes = Object.keys(labels);
  if (actions && engagementActionTypes[checkForAction]) {
    showToolTip = engagementTypes.some((engagementType: string) => {
      const act = actions?.[engagementActionTypes?.[checkForAction]?.[engagementType] as keyof CommentActions];
      if (act && labels[engagementType]) {
        tooltipText = labels[engagementType];
        return true;
      }
      return false;
    });
  }
  return {
    showToolTip,
    tooltipText,
  };
};

export const UserCommentAction = {
  HIDE: 'hide',
  DELETE: 'delete',
  BLOCK: 'block',
  LIKE: 'like',
  MUTE: 'mute',
  PRIVATEREPLY: 'privateReply',
  PUBLICREPLY: 'publicReply',
  REVIEW: 'review',
  ASSIGN: 'assign',
  SPAM: 'spam',
  AUTOMATEDREVIEW: 'automatedReview',
  AUTOMATEDASSIGN: 'automatedAssign',
  AUTOMATEDHIDE: 'automatedHide',
  AUTOMATEDDELETE: 'automatedDelete',
  AUTOMATEDBLOCK: 'automatedBlock',
  AUTOMATEDMUTE: 'automatedMute',
  AUTOMATEDLIKE: 'automatedLike',
  AUTOMATEDPRIVATEREPLY: 'automatedPrivateReply',
  AUTOMATEDPUBLICREPLY: 'automatedPublicReply',
  EXTERNALLIKE: 'externalLike',
  EXTERNALHIDE: 'externalHide',
  EXTERNALDELETE: 'externalDelete',
  EXTERNALUSERCOMMENTDELETE: 'externalUserCommentDelete',
  EXTERNALBLOCK: 'externalBlock',
  EXTERNALMUTE: 'externalMute',
  EXTERNALPUBLICREPLY: 'externalPublicReply',
};

export const ActionKeyToText = {
  [UserCommentAction.HIDE]: 'Hide',
  [UserCommentAction.DELETE]: 'Delete',
  [UserCommentAction.BLOCK]: 'Block',
  [UserCommentAction.LIKE]: 'Like',
  [UserCommentAction.MUTE]: 'Mute',
  [UserCommentAction.PRIVATEREPLY]: 'Private Reply',
  [UserCommentAction.PUBLICREPLY]: 'Public Reply',
  [UserCommentAction.REVIEW]: 'Review',
  [UserCommentAction.ASSIGN]: 'Assign',
  [UserCommentAction.SPAM]: 'Spam',
  [UserCommentAction.AUTOMATEDREVIEW]: 'Review',
  [UserCommentAction.AUTOMATEDASSIGN]: 'Assign',
  [UserCommentAction.AUTOMATEDHIDE]: 'Hide',
  [UserCommentAction.AUTOMATEDDELETE]: 'Delete',
  [UserCommentAction.AUTOMATEDBLOCK]: 'Block',
  [UserCommentAction.AUTOMATEDMUTE]: 'Mute',
  [UserCommentAction.AUTOMATEDLIKE]: 'Like',
  [UserCommentAction.AUTOMATEDPRIVATEREPLY]: 'Private Reply',
  [UserCommentAction.AUTOMATEDPUBLICREPLY]: 'Public Reply',
  [UserCommentAction.EXTERNALLIKE]: 'Like',
  [UserCommentAction.EXTERNALHIDE]: 'Hide',
  [UserCommentAction.EXTERNALDELETE]: 'Delete',
  [UserCommentAction.EXTERNALUSERCOMMENTDELETE]: 'User Comment Delete',
  [UserCommentAction.EXTERNALBLOCK]: 'Block',
  [UserCommentAction.EXTERNALMUTE]: 'Mute',
  [UserCommentAction.EXTERNALPUBLICREPLY]: 'Public Reply',
};

export const ActionKeyToType = {
  [UserCommentAction.HIDE]: 'Manual',
  [UserCommentAction.DELETE]: 'Manual',
  [UserCommentAction.BLOCK]: 'Manual',
  [UserCommentAction.LIKE]: 'Manual',
  [UserCommentAction.MUTE]: 'Manual',
  [UserCommentAction.PRIVATEREPLY]: 'Manual',
  [UserCommentAction.PUBLICREPLY]: 'Manual',
  [UserCommentAction.REVIEW]: 'Manual',
  [UserCommentAction.ASSIGN]: 'Manual',
  [UserCommentAction.SPAM]: 'Manual',
  [UserCommentAction.AUTOMATEDREVIEW]: 'Automated',
  [UserCommentAction.AUTOMATEDASSIGN]: 'Automated',
  [UserCommentAction.AUTOMATEDHIDE]: 'Automated',
  [UserCommentAction.AUTOMATEDDELETE]: 'Automated',
  [UserCommentAction.AUTOMATEDBLOCK]: 'Automated',
  [UserCommentAction.AUTOMATEDMUTE]: 'Automated',
  [UserCommentAction.AUTOMATEDLIKE]: 'Automated',
  [UserCommentAction.AUTOMATEDPRIVATEREPLY]: 'Automated',
  [UserCommentAction.AUTOMATEDPUBLICREPLY]: 'Automated',
  [UserCommentAction.EXTERNALLIKE]: 'External',
  [UserCommentAction.EXTERNALHIDE]: 'External',
  [UserCommentAction.EXTERNALDELETE]: 'External',
  [UserCommentAction.EXTERNALUSERCOMMENTDELETE]: 'External',
  [UserCommentAction.EXTERNALBLOCK]: 'External',
  [UserCommentAction.EXTERNALMUTE]: 'External',
  [UserCommentAction.EXTERNALPUBLICREPLY]: 'External',
};

export function getTooltipText(showToolTip: boolean, tooltipText: string, blocked: boolean, displayToolTip: boolean, hideText: string, unHideText: string) {
  if (showToolTip) {
    return tooltipText;
  } else if (blocked) {
    return unHideText;
  } else if (displayToolTip) {
    return hideText;
  }
  return '';
}
