/* eslint-disable max-lines */
import { v4 as uuid } from 'uuid';
import { PrivateReplyActionType } from '../../../pages/automation/constants';
import { getCreatedForObject } from '../../../pages/automation/create-gc/utils';
import { createCommentTypeForAPI } from '../../../pages/automation/transformer/ui-to-api';
import { ResponseActionType } from '../../../pages/automation/types';
import { defaultCommentTags } from '../../../pages/common/tag/tag.constants';
import { CommentTags } from '../../../pages/common/tag/tag.types';
import { commentTagFilterMap, commentTypes, TagGroupNames } from '../../../pages/dashboard/components/comments-viewer/comments-viewer.constants';
import { getTagFromIdAndGroup, sentimentLabel, sentimentRatingLabel } from '../../../pages/dashboard/components/comments-viewer/utils';
import { FetchPostDetailsResponse, CommentReceivedSubGroup } from '../../../pages/dashboard/components/comments-viewer/comments-viewer.types';
import { KeywordGroup, KeywordGroups } from '../../../pages/keyword-groups/keyword-groups.types';
import { templateTaggingGC } from '../../../pages/tags/components/manage-tag/components/tagging-gc-content/tagging-gc-content.constants';
import { getRouteForCampaignResponse } from '../../../pages/tags/utils';
import { FetchCommentsApiResponse, GenericObject, GetUserAccountApiResponse } from '../../../types';
import {
  FetchAssociatedCampaignsForKeywordGroupsApiResponse,
  FetchKeywordGroupsApiResponse,
  FetchPrivateReplyTagsResponse,
  FetchRecommendationsApiResponse,
  FetchStandardKeywordGroupsApiResponse,
  GCChildCondition,
  GCChildConditionsIfStatement,
  FetchPostsApiResponse,
  FetchCommentFilterApiResponse,
} from '../../../types/api-calls';
import { operatorsForDistanceValueUnit, operatorsSupportingValueUnit } from '../../components/tagging-gc-content/tagging-gc-content.constants';
import { PlatformActionConfigPayload } from '../../platform-action-config/platform-action-config.types';
import { inboxKeywordConditions, inboxKeywordVariable, platforms } from '../entities.constants';
import {
  Accounts,
  // Account,
  PlatformBots,
  PrivateReplyApiTag,
  PrivateReplyTag,
  PrivateReplyTags,
  SuggestedAction,
  VariablesTypeAPI,
  VariablesTypeUI,
  VariableType,
  TaggingGCs,
  TaggingGC,
  TaggingGCAssociatedCampaign,
  BotSettings,
  NlpConfig,
  Bot,
  Bots,
  PlatformUser,
  PlatformUsersUI,
  Posts,
  Post,
  PostsForRequest,
  labelTypeAPI,
  labelType,
  labelsTypeUI,
} from '../entities.types';
import { formatTagKey, getGcPriority } from '../utils';
import { getCommentsFromResponse, getCommentUsersFromComments } from './fetch-comments';
import { tagInfoBasedOnAction } from '../../../pages/common/recommendation-tag/utils';
import { trimExtraWhiteSpace } from '../../../pages/settings/utils';
import { getPlatformForSystemSettings } from '../../../pages/common/header/components/fb-page-selector';
export { covertPrivateRepliesTagsToUI } from './private-reply';

const getUserAccountApiToUi = (transformedAccounts: Accounts, accountIdFromUrl?: string, selectAccountId?: string) => {
  if (selectAccountId) return transformedAccounts[selectAccountId];
  const account = accountIdFromUrl && transformedAccounts[accountIdFromUrl];
  if (account) {
    return account;
  }
  const accounts = Object.values(transformedAccounts);
  if (accounts.length === 1) {
    return accounts[0];
  }

  // Object.values(transformedAccounts).sort((a: Account, b: Account) => {
  //   if (a.name < b.name) {
  //     return -1;
  //   }
  //   if (a.name > b.name) {
  //     return 1;
  //   }
  //   return 0;
  // })[0];
};
export const getAccountsApiToUi = (apiResponse: GetUserAccountApiResponse) => {
  const accountsMap: Accounts = {};
  apiResponse
    .filter((account: GetUserAccountApiResponse[0]) => account.isAmplifyLite)
    .forEach((account: GetUserAccountApiResponse[0]) => {
      accountsMap[account.id] = account;
    });
  // If user doesn't have access to any amplify lite account then select first account by default in the list.
  if (!Object.keys(accountsMap).length && apiResponse.length) {
    accountsMap[apiResponse[0].id] = apiResponse[0];
  }
  return accountsMap;
};

// eslint-disable-next-line max-lines-per-function
export const getBotsAndPlatformBotsApiToUi = (apiResponse: Bot[], platformActionConfig: PlatformActionConfigPayload) => {
  // isAdmin?: boolean, currentAccount?: any
  const platformBotsMap: PlatformBots = {};
  const bots: Bots = {};
  const supportedPlatformsFromConfig = platformActionConfig && Object.entries(platformActionConfig).length && new Set(Object.keys(platformActionConfig));

  apiResponse.forEach((bot: Bot) => {
    bots[bot.id] = bot;
    bot.platformBots?.forEach((platformBot: Bot['platformBots'][0]) => {
      const platformValue = getPlatformForSystemSettings(platformBot.platform, platformActionConfig);
      // If platform is twilio_sms then we need to check if indexMessage is enabled before showing the platform bot in the page selector.
      if (
        (supportedPlatformsFromConfig && supportedPlatformsFromConfig.has(platformValue)) ||
        platformBot.platform !== platforms.TWILIO_SMS ||
        bot?.config?.indexMessage?.enabled
      ) {
        platformBotsMap[platformBot.platformBotId] = {
          ...platformBot,
          description: bot.description,
          botId: bot.id,
          id: platformBot.id,
          instaUsername: platformBot.userName,
          platformAsInSystemSettings: platformValue,
        };
      }
    });
  });
  //special handling to not show a platform which has no valid tab
  // const isMobile = isMobileFun();
  // if (!isAdmin || !isMobile) {
  return { platformBots: platformBotsMap, bots };
  // const tabPermissions = {
  //   hasKeywordGroupViewPermission: true,
  //   hasTagAutomationViewPermission: true,
  //   viewACMReportsPermission: true,
  //   hasTemplateViewPermission: true,
  //   viewInbox: true,
  //   viewSegmentsPermission: true,
  // };

  // const optionsMapFiltered: PlatformBots = {};
  // Object.entries(platformBotsMap).forEach(([key, value]: any) => {
  //   const platformActions = platformActionConfig[value.platform]?.actions;
  //   const bot = bots?.[value.botId];
  //   const sidebarItems = getSidebar(
  //     true,
  //     tabPermissions,
  //     platformActions,
  //     value.platformAsInSystemSettings,
  //     { isMobile, isMessagingEnabled: bot?.config?.indexMessage?.enabled },
  //     true,
  //     true,
  //     bot?.type === botConfigTypeCliqz,
  //     Object.keys(platformActionConfig || {}),
  //     platformActionConfig,
  //     currentAccount,
  //   );
  //   if (sidebarItems.length > 1) {
  //     //we want to see if there are other tabs other than profile
  //     optionsMapFiltered[key] = value;
  //   } else if (sidebarItems.length === 1 && sidebarItems[0].subTabs?.length) {
  //     optionsMapFiltered[key] = value;
  //   }
  // });

  // return { platformBots: optionsMapFiltered, bots };
};
const getVariablesApiToUi = (apiResponse: VariablesTypeAPI) => {
  const variables: VariablesTypeUI = {};
  const variableToColorMap: GenericObject = {};
  apiResponse.forEach((variable: VariableType) => {
    variables[variable.id] = variable;
    variableToColorMap[variable.name] = variable.metadata?.color;
  });
  return { variables, variableToColorMap };
};

export const getKeywordsUiToApi = (keywordsInString: string = '') => {
  if (keywordsInString === '') {
    return {};
  }
  const keywordString = trimExtraWhiteSpace(keywordsInString?.replace(/\s*,\s*|\s+,/g, ' '));
  const keywords: any = keywordString !== '' && Array.from(new Set<any>(keywordString?.split(' ')));

  const allIfStatements: any = [];

  keywords?.forEach((keywordVal: string = '') => {
    allIfStatements.push({ ...inboxKeywordVariable, value: keywordVal });
  });
  let keywordConditions;
  if (allIfStatements?.length) {
    keywordConditions = { ...inboxKeywordConditions, childConditions: [{ ifStatements: allIfStatements, logicalOperator: 'or' }] };
  }

  return allIfStatements?.length ? keywordConditions : {};
};

export const getKeywordsApiToUi = (allConditions: any = []) => {
  const ifStatements = allConditions?.childConditions?.[0]?.ifStatements || [];
  const keywords: any = [];

  ifStatements?.length &&
    ifStatements.forEach((eachIf: any) => {
      eachIf?.value !== '' && keywords.push(eachIf.value);
    });

  return keywords.length ? keywords.join(' , ') : '';
};

const getCustomLabelsApiToUi = (apiResponse: labelTypeAPI) => {
  const labels: labelsTypeUI = {};
  const labelToColorMap: GenericObject = {};
  apiResponse.forEach((label: labelType) => {
    labels[label.id] = { ...label, ...(label.conditions && { keywords: getKeywordsApiToUi(label.conditions) }) };
    labelToColorMap[label.name] = label.metadata?.color;
  });

  return { labels, labelToColorMap };
};

const fetchCommentsApiToUi = (apiResponse: FetchCommentsApiResponse, privateReplyTags: PrivateReplyTags) => {
  const comments = (apiResponse && getCommentsFromResponse(apiResponse, privateReplyTags)) || {};
  const commentUsers = (apiResponse && getCommentUsersFromComments(comments)) || {};

  return {
    comments,
    commentUsers,
  };
};
const fetchKeywordGroupsApiToUi = (apiResponse: FetchKeywordGroupsApiResponse | null) => {
  const entitiesFiltered: FetchStandardKeywordGroupsApiResponse = {};
  apiResponse &&
    Object.keys(apiResponse.entities)
      .filter((entityName: string) => {
        const type = (apiResponse.entities[entityName] && apiResponse.entities[entityName].type) || '';
        return type === 'keyword';
      })
      .forEach((name: string) => {
        entitiesFiltered[name] = apiResponse.entities[name];
      });
  const keywordGroups: KeywordGroups = transformKeywordsToUI(entitiesFiltered, true);
  const entitiesId = apiResponse?.id || '';
  const lastUpdatedDate = apiResponse?.lastUpdatedDate || '';
  return {
    keywordGroups,
    entitiesId,
    lastUpdatedDate,
    botTrainingInfo: {
      isTrained: apiResponse?.trained,
      lastTrained: apiResponse?.lastTrained,
      trainingStatus: apiResponse?.trainingStatus,
    },
  };
};
export const transformKeywordsToUI = (keywordGroups: FetchStandardKeywordGroupsApiResponse, isCustom?: boolean) => {
  const keywordGroupsTranformed: KeywordGroups = {};

  Object.keys(keywordGroups).forEach((entityName: string) => {
    const entityValues = (keywordGroups[entityName] && keywordGroups[entityName].values) || [];
    keywordGroupsTranformed[entityName] = {
      name: entityName,
      associatedCampaigns: [],
      custom: !!isCustom,
      keywords: entityValues.map((value: { value: string; synonyms: string[] }) => value.value),
      subgroups: entityValues.map((value: { value: string; synonyms: string[] }) => {
        return {
          name: value.value,
          synonyms: value.synonyms,
        };
      }),
    };
  });
  return keywordGroupsTranformed;
};
const fetchAssociatedCampaignsForKeywordGroupsApiToUi = (apiResponse: FetchAssociatedCampaignsForKeywordGroupsApiResponse | null, keywordGroups: KeywordGroups) => {
  const updatedKeywordGroups = { ...keywordGroups };
  apiResponse &&
    apiResponse
      .filter((campaign: FetchAssociatedCampaignsForKeywordGroupsApiResponse[0]) => campaign.type === 'tagging')
      .forEach((campaign: FetchAssociatedCampaignsForKeywordGroupsApiResponse[0]) => {
        const associatedKeywordGroups = findAssociatedKeywordGroups(campaign, keywordGroups);
        associatedKeywordGroups.forEach((associatedKeywordGroup: string) => {
          if (
            associatedKeywordGroup &&
            !updatedKeywordGroups[associatedKeywordGroup].associatedCampaigns.find((assocCampaign: TaggingGCAssociatedCampaign) => assocCampaign.name === campaign.name)
          ) {
            updatedKeywordGroups[associatedKeywordGroup] = {
              ...updatedKeywordGroups[associatedKeywordGroup],
              associatedCampaigns: [
                ...updatedKeywordGroups[associatedKeywordGroup].associatedCampaigns,
                { name: campaign.name, id: campaign.id, uiRoute: 'tags', action: campaign.actions[0].replyMessage, paused: campaign.paused },
              ],
            };
          }
        });
      });
  return {
    updatedKeywordGroups,
  };
};
const findAssociatedKeywordGroups = (campaign: FetchAssociatedCampaignsForKeywordGroupsApiResponse[0], keywordGroups: KeywordGroups) => {
  let foundKeywordGroups: string[] = [];
  if (
    campaign?.conditions?.childConditions?.length &&
    campaign.conditions.childConditions.some((condition: NonNullable<NonNullable<FetchAssociatedCampaignsForKeywordGroupsApiResponse[0]['conditions']>['childConditions']>[0]) => {
      if (
        condition.ifStatements?.length &&
        condition.ifStatements.some((ifStatement: { variable: { name: string; type: string } }) => {
          if (keywordGroups[ifStatement.variable.name] && ifStatement.variable.type === 'entity') {
            foundKeywordGroups = [...foundKeywordGroups, ifStatement.variable.name];
            return true;
          }
          return false;
        })
      ) {
        return true;
      }
      return false;
    })
  )
    return foundKeywordGroups;
  return [];
};
const fetchStandardKeywordGroupsApiToUi = (standardKeywordGroups: FetchStandardKeywordGroupsApiResponse, keywordGroups: KeywordGroups) => {
  const updatedKeywordGroups = { ...keywordGroups };
  Object.keys(standardKeywordGroups).forEach((standardKeywordGroupName: string) => {
    if (updatedKeywordGroups[standardKeywordGroupName]) {
      const updatedKeywordGroup = { ...updatedKeywordGroups[standardKeywordGroupName] };
      updatedKeywordGroup.custom = false;
      updatedKeywordGroups[standardKeywordGroupName] = updatedKeywordGroup;
    }
  });
  return { updatedKeywordGroups };
};

const tranformKeywordsForAccountApiToUi = (standardKeywordGroups: KeywordGroups, keywordGroupsInConfig: KeywordGroups) => {
  const keywordsInConfig: { [key: string]: KeywordGroup } = { ...keywordGroupsInConfig };

  Object.keys(keywordsInConfig).forEach((keywordName: string) => {
    const updatedKeywordGroup = { ...keywordsInConfig[keywordName] };
    if (!standardKeywordGroups[keywordName]) {
      updatedKeywordGroup.custom = true;
      keywordsInConfig[keywordName] = updatedKeywordGroup;
    }
  });
  return { keywordsInConfig };
};
/* eslint-disable max-lines-per-function */

const fetchTaggingGCsApiToUi = (response: FetchRecommendationsApiResponse) => {
  const taggingGCs: TaggingGCs = {};
  const taggingGCsMapByName: TaggingGCs = {};
  response.forEach((campaign: FetchRecommendationsApiResponse[0]) => {
    if (campaign.type === 'tagging') {
      taggingGCs[campaign.id] = {
        title: campaign.name,
        description: campaign.description,
        id: campaign.id,
        custom: !(campaign.uiMetadata?.type === 'standard'),
        active: !campaign.paused,
        rawGC: campaign,
        associatedCampaigns: [],
        actions: campaign.actions.map((action: ResponseActionType) => ({ ...action, id: uuid() })),
        conditions: {
          ...campaign.conditions,
          childConditions: campaign.conditions?.childConditions?.map((childCondition: GCChildCondition) => ({
            ...{
              logicalOperator: childCondition.logicalOperator,
              ifStatements: childCondition.ifStatements.map((ifStatement: GCChildConditionsIfStatement) => {
                return { ...ifStatement, id: uuid() };
              }),
            },
            id: uuid(),
          })),
        },
        createFor: getCreatedForObject(campaign.commentType, campaign.type, campaign.targetPostMentions),
      };
      taggingGCsMapByName[formatTagKey(campaign.name)] = {
        title: campaign.name,
        description: campaign.description,
        id: campaign.id,
        custom: !(campaign.uiMetadata?.type === 'standard'),
        active: !campaign.paused,
        rawGC: campaign,
        associatedCampaigns: [],
      };
    }
  });
  response.forEach((campaign: FetchRecommendationsApiResponse[0]) => {
    if (campaign.type !== 'tagging') {
      const conditions = campaign?.conditions?.childConditions;
      let foundRecId = '';
      conditions &&
        conditions.length > 0 &&
        conditions.forEach((condition: GCChildCondition) => {
          const { ifStatements } = condition;
          ifStatements.forEach((ifStatement: GCChildConditionsIfStatement) => {
            if (taggingGCsMapByName[formatTagKey(ifStatement.value)]) {
              foundRecId = taggingGCsMapByName[formatTagKey(ifStatement.value)].id;
              taggingGCs[foundRecId].associatedCampaigns = [
                ...taggingGCs[foundRecId].associatedCampaigns,
                { name: campaign.name, id: campaign.id, uiRoute: getRouteForCampaignResponse(campaign.actions[0].type), action: campaign.actions[0].type, paused: campaign.paused },
              ];
            }
          });
        });
    }
  });
  return { taggingGCs };
};
const updateRecommendationActiveStateUiToApi = (recommendation: TaggingGC, paused: boolean) => {
  const updatedRecommendation: FetchRecommendationsApiResponse[0] = { ...recommendation.rawGC!, paused };
  delete updatedRecommendation.botId;
  return updatedRecommendation;
};
const fetchPrivateReplyTagsApiToUi = (response: FetchPrivateReplyTagsResponse) => {
  const privateReplyTags: PrivateReplyTags = {};
  response.forEach((tag: FetchPrivateReplyTagsResponse[0]) => {
    privateReplyTags[formatTagKey(tag.name)] = {
      id: tag.id,
      name: tag.name,
      actionType: (tag.actions.length && (tag.actions[0].type === PrivateReplyActionType.TEXT ? PrivateReplyActionType.PRIVATE : tag.actions[0].type)) || 'noaction',
      description: tag.description,
    };
  });
  return { privateReplyTags };
};

const taggingGCUiToApi = (recommendation: TaggingGC, recommendedActions: SuggestedAction[]) => {
  const updatedRecommendation: { [key: string]: unknown } = {
    ...templateTaggingGC,
    ...(recommendation.id && { id: recommendation.id }),
    priority: recommendation.active ? (recommendation.rawGC?.priority ? recommendation.rawGC.priority : getGcPriority(recommendedActions)) : getGcPriority(recommendedActions),
    actions: [{ type: 'tag', replyMessage: recommendation.title }],
    name: recommendation.title,
    ...(recommendation.conditions?.childConditions && {
      conditions: {
        ...recommendation.conditions,
        childConditions: recommendation.conditions?.childConditions?.map((childCondition: GCChildCondition) => {
          const childConditionWithIdsRemoved = {
            ...{
              logicalOperator: childCondition.logicalOperator,
              ifStatements: childCondition.ifStatements.map((ifStatement: GCChildConditionsIfStatement) => {
                const updatedIfStatement = { ...ifStatement };
                const hasDistanceOperators = operatorsForDistanceValueUnit.includes(ifStatement.condition);
                updatedIfStatement.valueUnit = operatorsSupportingValueUnit.includes(ifStatement.condition) || hasDistanceOperators ? ifStatement.valueUnit : undefined;
                if (!hasDistanceOperators && updatedIfStatement.metadata && updatedIfStatement.metadata.distanceFrom) {
                  const metaData = { ...updatedIfStatement.metadata };
                  delete metaData.distanceFrom;
                  updatedIfStatement.metadata = metaData;
                }
                delete updatedIfStatement.id;
                return updatedIfStatement;
              }),
            },
          };
          return childConditionWithIdsRemoved;
        }),
      },
    }),
  };
  if (recommendation.createFor) {
    updatedRecommendation.commentType = createCommentTypeForAPI(recommendation.createFor);
  }
  return updatedRecommendation;
};
const privateReplyTagUiToApi = (tag: PrivateReplyTag): PrivateReplyApiTag => {
  let actions: SuggestedAction[] = [];
  if (tag.actionType !== 'noaction') actions = [{ type: tag.actionType === 'privateReply' ? 'text' : tag.actionType }];
  const updatedTag: PrivateReplyApiTag = {
    actions,
    ...(tag.id && { id: tag.id }),
    name: tag.name,
    description: tag.description,
  };

  return updatedTag;
};
const getBotSettingsApiToUi = (botSettings: BotSettings) => {
  const isOverrideNlpEnabled = getIsOverrideNlpEnabled(botSettings);

  return { isOverrideNlpEnabled };
};

const getIsOverrideNlpEnabled = (botSettings: BotSettings) => {
  const configKeys = botSettings.nlpConfig ? Object.keys(botSettings.nlpConfig) : [];
  //No other config present
  if (configKeys && configKeys.length && (configKeys.length > 1 || configKeys[0] !== 'default')) {
    return true;
  }
  const messageConfig = botSettings.nlpConfig?.default?.messageNLPConfig;
  const commentConfig = botSettings.nlpConfig?.default?.commentNLPConfig;
  const defaultProject = botSettings.nlpConfig?.default.project;

  const configs = [messageConfig, commentConfig];
  //check if projects are different

  if (checkBotsDifferentAdvancedCondition(messageConfig, commentConfig)) {
    return true;
  }

  const checkConfigKeys = configs.some((config?: NlpConfig) => {
    //If config is present
    if (config?.configuration) {
      //Check if platform not amplify
      if (config?.provider && config.provider !== 'amplify') return true;

      // const configurationKeys = Object.keys(config.configuration);
      //Check if key other than project or model is present
      if (isAdvancedConfigurationKeys(config)) {
        return true;
      }

      //Check if config project same as defaultProject botId
      if (config.configuration.project && config.configuration.project !== (defaultProject || botSettings.botId)) return true;
    }
    return false;
  });
  if (checkConfigKeys) {
    return true;
  }
  return false;
};

const checkBotsDifferentAdvancedCondition = (messageConfig?: NlpConfig, commentConfig?: NlpConfig) => {
  const messagePrj = messageConfig?.configuration && messageConfig.configuration.project && messageConfig.configuration.project;
  const commentPrj = commentConfig?.configuration && commentConfig.configuration.project && commentConfig.configuration.project;
  if (messagePrj && commentPrj && messagePrj !== commentPrj) {
    return true;
  }
  return false;
};

const isAdvancedConfigurationKeys = (config: NlpConfig) => {
  if (config.configuration) {
    const configurationKeys = Object.keys(config.configuration);
    const keysSupported = ['project', 'model'];
    // if (configurationKeys && configurationKeys.length && (configurationKeys.length > 1 || configurationKeys[0] !== 'project')) {

    if (configurationKeys && configurationKeys.length && (configurationKeys.length > 2 || configurationKeys.find((x: string) => !keysSupported.includes(x)))) {
      return true;
    }
  }
  return false;
};

export const convertPlatformUsersToUI = (users: PlatformUser[]) => {
  const platformUsers: PlatformUsersUI = {};

  users.forEach((user: PlatformUser) => {
    platformUsers[user.platformUserId] = {
      ...user,
      rawUser: user,
    };
  });

  return platformUsers;
};

export const convertPostsToUI = (posts: FetchPostsApiResponse, postforRequests: PostsForRequest[]) => {
  const platformPosts: Posts = {};
  let transformedPosts: Post[] = [];
  posts = posts.filter((post: any) => post.id !== '');
  transformedPosts = posts.map((post: any) => {
    const postforRequest = postforRequests.find((pt: PostsForRequest) => pt.id === post.id);
    return {
      ...post,
      ...postforRequest,
    };
  });
  transformedPosts.forEach((post: Post) => {
    platformPosts[post.id] = {
      ...post,
    };
  });
  return platformPosts;
};

export const transformCommentFilterTagsApiToUI = (tags: FetchCommentFilterApiResponse, privateReplyTags: PrivateReplyTags) => {
  const searchTags: CommentTags = defaultCommentTags(true);
  const commentFilterTagMapped = Object.entries(commentTagFilterMap).reduce((res: any, [key, value]: [string, string]) => ({ ...res, [value]: key }), {});
  Object.keys(tags).forEach((i: string) => {
    const tagType = commentFilterTagMapped[i];
    switch (tagType) {
      case TagGroupNames.SENTIMENTS:
        const commentSentiments = tags[i].buckets
          .sort((tag1: any, tag2: any) => tag2.doc_count - tag1.doc_count)
          .map((tag: any) => getTagFromIdAndGroup(sentimentLabel[tag.key.toUpperCase()] || '', tagType))
          .reduce((res: any, value: any) => ({ ...res, [value.id]: value }), {});
        searchTags[tagType as keyof CommentTags] = { ...searchTags[tagType as keyof CommentTags], ...commentSentiments };
        break;
      case TagGroupNames.SENTIMENT:
        const commentSentiment = tags[i].buckets
          .sort((tag1: any, tag2: any) => tag2.doc_count - tag1.doc_count)
          .map((tag: any) => getTagFromIdAndGroup(sentimentRatingLabel[tag.key.toUpperCase()] || '', tagType))
          .reduce((res: any, value: any) => ({ ...res, [value.id]: value }), {});
        searchTags[tagType as keyof CommentTags] = { ...searchTags[tagType as keyof CommentTags], ...commentSentiment };
        break;
      case TagGroupNames.RECOMMENDATIONS:
        searchTags[tagType as keyof CommentTags] = tags[i].buckets
          .sort((tag1: any, tag2: any) => tag2.doc_count - tag1.doc_count)
          .map((tag: any) => {
            const color =
              privateReplyTags[formatTagKey(tag.key)] && privateReplyTags[formatTagKey(tag.key)].actionType
                ? tagInfoBasedOnAction(privateReplyTags[formatTagKey(tag.key)].actionType).color
                : tagInfoBasedOnAction('').color;
            return { ...getTagFromIdAndGroup(tag.key, tagType), color };
          })
          .reduce((res: any, value: any) => ({ ...res, [value.id]: value }), {});
        break;
      case TagGroupNames.REPLIES:
        searchTags[tagType as keyof CommentTags] = commentTypes.reduce((res: any, value: any) => {
          const newRes = { ...res, [value.id]: value };
          return newRes;
        }, {});
        break;
      case TagGroupNames.POST_TYPES:
        searchTags[tagType as keyof CommentTags] = { ...searchTags[tagType as keyof CommentTags] };
        break;
      default:
        searchTags[tagType as keyof CommentTags] = tags[i].buckets
          .sort((tag1: any, tag2: any) => tag2.doc_count - tag1.doc_count)
          .map((tag: any) => getTagFromIdAndGroup(tag.key, tagType))
          .reduce((res: any, value: any) => {
            const newRes = { ...res, [value.id]: value };
            //Uncomment for removing duplicate entries of two cased keywords
            // const casedKeys = Object.keys(res)
            //   .map((key: string) => [key, key.toLowerCase()])
            //   .find((i: any) => i[1] === value.id.toLowerCase());
            // if (casedKeys) {
            //   delete newRes[casedKeys[1]];
            // }
            //
            return newRes;
          }, {});
    }
  });
  return searchTags;
};
export const tranformPostFormat = (response: FetchPostDetailsResponse[]) => {
  const formattedObjects: PostsForRequest[] = [];
  let totalComments = 0;
  response.forEach(({ key, subGroups }: FetchPostDetailsResponse) => {
    subGroups.forEach((post: CommentReceivedSubGroup) => {
      const postObject = {
        id: post.key,
        postType: key,
        totalComments: post.commentsReceived ? post.commentsReceived : 0,
      };
      totalComments += post.commentsReceived ? post.commentsReceived : 0;
      formattedObjects.push(postObject);
    });
  });
  return { posts: formattedObjects, totalComments };
};

const transformations = {
  getUserAccountApiToUi,
  getAccountsApiToUi,
  getBotsAndPlatformBotsApiToUi,
  fetchCommentsApiToUi,
  fetchKeywordGroupsApiToUi,
  fetchAssociatedCampaignsForKeywordGroupsApiToUi,
  fetchStandardKeywordGroupsApiToUi,
  getVariablesApiToUi,
  getCustomLabelsApiToUi,
  getKeywordsUiToApi,
  getKeywordsApiToUi,
  fetchTaggingGCsApiToUi,
  updateRecommendationActiveStateUiToApi,
  fetchPrivateReplyTagsApiToUi,
  taggingGCUiToApi,
  privateReplyTagUiToApi,
  transformKeywordsToUI,
  tranformKeywordsForAccountApiToUi,
  getBotSettingsApiToUi,
  convertPlatformUsersToUI,
  convertPostsToUI,
  transformCommentFilterTagsApiToUI,
};
export default transformations;
