import { ReactComponent as Announce } from '../../../assets/svg/dashboard/announce.svg';
import { ReactComponent as HomeIcon } from '../../../assets/svg/dashboard/home.svg';
import { ReactComponent as TagsIcon } from '../../../assets/svg/dashboard/tags.svg';
import { ReactComponent as TemplatesIcon } from '../../../assets/svg/dashboard/templates.svg';
import { ReactComponent as KeywordsIcon } from '../../../assets/svg/dashboard/keyword-groups.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/svg/dashboard/reports.svg';
import { ReactComponent as Inbox } from '../../../assets/svg/dashboard/inbox.svg';
import { ReactComponent as CommentsIcon } from '../../../assets/svg/comment-white.svg';
import { ReactComponent as Broadcast } from '../../../assets/svg/broadcast/broadcast.svg';
import { ReactComponent as Segment } from '../../../assets/svg/segments-icon.svg';
import { ReactComponent as Settings } from '../../../assets/svg/settings.svg';
import { ReactComponent as Books } from '../../../assets/svg/book.svg';
import { ReactComponent as Audience } from '../../../assets/svg/audience/users.svg';

import { automationSubTabs } from '../../automation/constants';
import { deeplyLinkedRoutes } from '../../../common/page-ui-state/constants';
// import { actions } from '../../../config/platform-action-map';
import { actionsInBrandLift, actionsInCommentModeration, actionsInSmartReplies } from '../../automation/config';
import { pageKeysForFilter } from '../../dashboard/constants';
// import { CommentsModeConstants } from '../../dashboard/constants';

export const AmpLiteNavTabs = {
  HOME: 'Home',
  DASHBOARD: 'Dashboard',
  MODERATION: 'Messages',
  COMMENTS: 'Comments',
  INBOX: 'Inbox',
  KEYWORD_GROUPS: 'Keyword Groups',
  UTILITY: 'Utility',
  TAGS: 'Tags',
  AUTOMATION: 'Automation',
  TEMPLATES: 'Templates',
  REPORTS: 'Reports',
  BROADCASTS: 'Broadcasts',
  SETTINGS: 'Settings',
  ADMIN_TOOLS: 'Admin Tools',
  SMS_DEPLOYMENT: 'SMS Deployment',
  SEGMENTS: 'Segments & Cohorts',
  INGEST_LIST: 'Ingest List',
  BOT_SETTINGS: 'Bot Settings',
  RESOURCES: 'Resources',
  ACCOUNT_SETTINGS: 'Account',
  AUDIENCE: 'Audience',
  MANAGELABELS: 'Manage Labels',
  SENTIMENT_ANALYSIS: 'Sentiment Analysis',
  SMART_REPLIES: 'Smart Replies',
};

export const AmpLitePages = {
  HOME: 'home',
  DASHBOARD: 'dashboard',
  COMMENTS: 'comments',
  MENTIONS: 'mentions',
  INBOX: 'inbox',
  KEYWORD_GROUPS: 'keywordGroups',
  UTILITY: 'utility',
  TAGS: 'tags',
  TEMPLATES: 'templates',
  COMMENT_MODERATION: automationSubTabs.COMMENT_MODERATION,
  BRAND_LIFT: automationSubTabs.BRAND_LIFT,
  SMART_REPLIES: automationSubTabs.SMART_REPLIES,
  REPORTS: 'reports',
  BROADCASTS: 'broadcasts',
  SMS_DEPLOYMENT: 'smsDeployment',
  SEGMENTS: 'segments',
  BOT_SETTINGS: 'settings',
  RESOURCES: 'resources',
  AUDIENCE: 'audience',
  INGEST_LIST: 'ingestList',
  CHOOSE_ACCOUNT: 'chooseAccount',
  MANAGELABELS: 'manageLabels',
  SENTIMENT_ANALYSIS: 'sentimentAnalysis',
};

export const AmpLiteSideBarItems = [
  {
    title: AmpLiteNavTabs.DASHBOARD,
    route: `/:accountId/:platformBotId/${AmpLitePages.DASHBOARD}`,
    page: AmpLitePages.DASHBOARD,
    subTabs: [],
    icon: HomeIcon,
  },

  {
    title: AmpLiteNavTabs.HOME,
    route: `/:accountId/:platformBotId/${AmpLitePages.HOME}`,
    page: AmpLitePages.HOME,
    subTabs: [],
    icon: HomeIcon,
  },
  {
    title: AmpLiteNavTabs.MODERATION,
    icon: CommentsIcon,
    subTabs: [
      {
        title: AmpLiteNavTabs.COMMENTS,
        route: `/:accountId/:platformBotId/messages`,
        page: AmpLitePages.COMMENTS,
        icon: CommentsIcon,
      },
      {
        title: AmpLiteNavTabs.INBOX,
        route: `/:accountId/:platformBotId/${AmpLitePages.INBOX}`,
        page: AmpLitePages.INBOX,
        subTabs: [],
        icon: Inbox,
        pageKeyForFilter: pageKeysForFilter.INBOX,
      },
    ],
  },
  {
    route: deeplyLinkedRoutes.broadcasts,
    title: AmpLiteNavTabs.BROADCASTS,
    page: AmpLitePages.BROADCASTS,
    icon: Broadcast,
    subTabs: [],
  },
  {
    title: AmpLiteNavTabs.SEGMENTS,
    route: `/:accountId/:platformBotId/${AmpLitePages.SEGMENTS}`,
    page: AmpLitePages.SEGMENTS,
    icon: Segment,
    subTabs: [],
  },
  {
    route: deeplyLinkedRoutes.audience,
    title: AmpLiteNavTabs.AUDIENCE,
    page: AmpLitePages.AUDIENCE,
    icon: Audience,
    subTabs: [],
  },
  {
    title: AmpLiteNavTabs.AUTOMATION,
    icon: Announce,
    subTabs: [
      {
        route: `/:accountId/:platformBotId/${AmpLitePages.COMMENT_MODERATION}`,
        title: 'Moderation',
        page: AmpLitePages.COMMENT_MODERATION,
        actions: actionsInCommentModeration,
      },
      {
        route: `/:accountId/:platformBotId/${AmpLitePages.BRAND_LIFT}`,
        title: 'Brand Lift',
        page: AmpLitePages.BRAND_LIFT,
        actions: actionsInBrandLift,
      },
      {
        route: `/:accountId/:platformBotId/${AmpLitePages.SMART_REPLIES}`,
        title: 'Smart Replies',
        page: AmpLitePages.SMART_REPLIES,
        actions: actionsInSmartReplies,
      },
    ],
  },
  { route: deeplyLinkedRoutes.reports, title: AmpLiteNavTabs.REPORTS, page: AmpLitePages.REPORTS, icon: ReportsIcon, subTabs: [] },
  {
    title: AmpLiteNavTabs.SETTINGS,
    icon: Settings,
    subTabs: [
      {
        title: AmpLiteNavTabs.KEYWORD_GROUPS,
        route: `/:accountId/:platformBotId/${AmpLitePages.KEYWORD_GROUPS}`,
        page: AmpLitePages.KEYWORD_GROUPS,
        subTabs: [],
        icon: KeywordsIcon,
      },
      {
        title: AmpLiteNavTabs.TAGS,
        route: `/:accountId/:platformBotId/${AmpLitePages.TAGS}`,
        page: AmpLitePages.TAGS,
        subTabs: [],
        icon: TagsIcon,
      },
      {
        title: AmpLiteNavTabs.TEMPLATES,
        route: `/:accountId/:platformBotId/${AmpLitePages.TEMPLATES}`,
        page: AmpLitePages.TEMPLATES,
        subTabs: [],
        icon: TemplatesIcon,
      },
      {
        title: AmpLiteNavTabs.MANAGELABELS,
        route: `/:accountId/:platformBotId/${AmpLitePages.MANAGELABELS}`,
        page: AmpLitePages.MANAGELABELS,
        subTabs: [],
        // icon: TemplatesIcon,
      },
      {
        title: AmpLiteNavTabs.INGEST_LIST,
        route: `/:accountId/:platformBotId/${AmpLitePages.INGEST_LIST}`,
        page: AmpLitePages.INGEST_LIST,
        subTabs: [],
      },
      {
        title: AmpLiteNavTabs.UTILITY,
        route: `/:accountId/:platformBotId/${AmpLitePages.UTILITY}`,
        page: AmpLitePages.UTILITY,
        subTabs: [],
      },
      {
        title: AmpLiteNavTabs.ACCOUNT_SETTINGS,
        route: `/:accountId/:platformBotId/${AmpLitePages.BOT_SETTINGS}`,
        page: AmpLitePages.BOT_SETTINGS,
        subTabs: [],
      },
    ],
  },
  {
    title: AmpLiteNavTabs.RESOURCES,
    route: `/:accountId/:platformBotId/${AmpLitePages.RESOURCES}`,
    page: AmpLitePages.RESOURCES,
    subTabs: [],
    icon: Books,
  },

  // {
  //   title: AmpLiteNavTabs.REPORT,
  //   route: `/${AmpLitePages.REPORT}`,
  //   page: AmpLitePages.REPORT,
  //   subTabs: [],
  // },
];
export const templateConstants = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const templateTabsRoutes = {
  [templateConstants.ACTIVE]: 'active',
  [templateConstants.ARCHIVED]: 'archived',
};
