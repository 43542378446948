/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { AnalysisState, AudiencePageType, AudienceState, FieldGroupType, FilterOption } from './../type';
import {
  setCurrentAudienceTab,
  setFanGroupsByField,
  setFanChangeCountWithBroadcastInfo,
  applyAudienceFilters,
  updateTotalFansCount,
  storeMembersData,
  resetAudienceTab,
  storeMembersCount,
  // storeSessionInAudience,
  setFilterOptionsForField,
  updateMemberData,
  removeBlockedAudienceUser,
} from '../actions/index';
import { FanGroupCategories, FanGroupFields } from '../constants';
import { ALLCONSTANT } from '../../automation/constants';

const AGEFILTEROPTIONS = (FanGroupFields[FanGroupCategories.AGE]?.establishedGroups || []).reduce(
  (allAgeGroups: { [key: string]: FilterOption }, group: FieldGroupType) => ({
    ...allAgeGroups,
    [group.value]: { label: group.label, value: group.value, from: group.from, to: group.to },
  }),
  {},
);

const initialAnalysisState: AnalysisState = {
  fanGroupsByField: {
    COUNTRY: {},
    AGE: {},
    GENDER: {},
    STATE: {},
    CITY: {},
    LOCALE: {},
    TIMEZONE: {},
    LABEL: {},
  },
  filterOptionsByField: {
    COUNTRY: {},
    AGE: AGEFILTEROPTIONS,
    GENDER: {},
    STATE: {},
    CITY: {},
    LOCALE: {},
    TIMEZONE: {},
    LABEL: {},
  },
  recentFanChangeCount: {},
  fanChangeCountWithBroadcastInfo: {},
  totalFansWithFilters: 0,
  totalFans: 0,
};

const initialNewMembersState = {
  members: {},
  hasMore: false,
  totalMemberCount: 0,
};

const initialDataState: { [key in AudiencePageType]: any } = {
  analysis: initialAnalysisState,
  newMembers: initialNewMembersState,
  segmentUsers: initialNewMembersState,
};

const initialAppliedFilterState: { [key in AudiencePageType]: any } = {
  analysis: {
    appliedFilters: { COUNTRY: [], STATE: [], CITY: [], LABEL: [], GENDER: [], TIMEZONE: [], LOCALE: [], AGE: null },
    subscriptionEndDate: null,
  },
  newMembers: { subscribed: true },
  segmentUsers: {
    included: [ALLCONSTANT],
    excluded: [],
    subscribed: false,
    searchId: '',
  },
};

export const initialState: AudienceState = {
  currentActiveTab: '',
  ...initialDataState,
  filters: initialAppliedFilterState,
};

/*eslint-disable max-lines-per-function*/
const audienceReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder.addCase(setCurrentAudienceTab, (state: typeof initialState, action: ReturnType<typeof setCurrentAudienceTab>) => {
    state.currentActiveTab = action.payload;
  });
  builder.addCase(setFanGroupsByField, (state: typeof initialState, action: ReturnType<typeof setFanGroupsByField>) => {
    state.analysis.fanGroupsByField = action.payload.fanGroupsByField;
    state.analysis.totalFansWithFilters = action.payload.totalFansWithFilters;
  });
  builder.addCase(setFanChangeCountWithBroadcastInfo, (state: typeof initialState, action: ReturnType<typeof setFanChangeCountWithBroadcastInfo>) => {
    state.analysis.fanChangeCountWithBroadcastInfo = action.payload;
  });
  builder.addCase(setFilterOptionsForField, (state: typeof initialState, action: ReturnType<typeof setFilterOptionsForField>) => {
    const { filterOptions, filterType } = action.payload;
    state.analysis.filterOptionsByField = { ...state.analysis.filterOptionsByField, [filterType]: filterOptions };
  });
  builder.addCase(applyAudienceFilters, (state: typeof initialState, action: ReturnType<typeof applyAudienceFilters>) => {
    const { pageName, isResetFilter, filters, deepMergeFilters } = action.payload;
    if (isResetFilter) {
      state.filters[pageName] = initialAppliedFilterState[pageName];
    } else {
      if (deepMergeFilters) {
        Object.keys(filters).forEach((value: string) => {
          if (typeof state.filters[pageName][value] === 'object' && !Array.isArray(state.filters[pageName][value])) {
            filters[value] = {
              ...state.filters[pageName][value],
              ...filters[value],
            };
          }
        });
      }
      state.filters[pageName] = { ...state.filters[pageName], ...filters };
    }
  });
  builder.addCase(updateTotalFansCount, (state: typeof initialState, action: ReturnType<typeof updateTotalFansCount>) => {
    state.analysis.totalFans = action.payload.totalFans;
    state.analysis.recentFanChangeCount = action.payload.recentFanChangeCount;
  });
  builder.addCase(storeMembersData, (state: typeof initialState, action: ReturnType<typeof storeMembersData>) => {
    const { pageName, members = [], hasMore, isLoadingMore } = action.payload;
    let storedNewMembers = isLoadingMore ? { ...state[pageName].members } : {};
    if (Array.isArray(members)) {
      members.forEach((element: any) => {
        storedNewMembers = { ...storedNewMembers, [element.platformUserId]: element };
      });
    }
    state[pageName].hasMore = hasMore;
    state[pageName].members = storedNewMembers;
  });
  builder.addCase(resetAudienceTab, (state: typeof initialState, action: ReturnType<typeof resetAudienceTab>) => {
    const { pageName, retainFilters } = action.payload;
    state[pageName] = initialDataState[pageName];
    if (!retainFilters) {
      state.filters[pageName] = initialAppliedFilterState[pageName];
    }
  });
  builder.addCase(storeMembersCount, (state: typeof initialState, action: ReturnType<typeof storeMembersCount>) => {
    const { pageName, memberCount } = action.payload;
    if (state[pageName]) {
      state[pageName].totalMemberCount = memberCount;
    }
  });
  builder.addCase(updateMemberData, (state: typeof initialState, action: ReturnType<typeof updateMemberData>) => {
    const { member, pageName } = action.payload;
    const platformUserId = member?.platformUserId;
    if (member && platformUserId && state[pageName].members?.[platformUserId]) {
      state[pageName].members = { ...state[pageName].members, [platformUserId]: member };
    }
  });
  builder.addCase(removeBlockedAudienceUser, (state: typeof initialState, action: ReturnType<typeof removeBlockedAudienceUser>) => {
    delete state?.segmentUsers?.members?.[action.payload];
    delete state?.newMembers?.members?.[action.payload];
    if (state?.segmentUsers?.totalMemberCount && state.segmentUsers.totalMemberCount !== 0) state.segmentUsers.totalMemberCount = state?.segmentUsers?.totalMemberCount - 1;
    if (state?.newMembers?.totalMemberCount && state.newMembers.totalMemberCount !== 0) state.newMembers.totalMemberCount = state?.newMembers?.totalMemberCount - 1;
  });
  // builder.addCase(storeSessionInAudience, (state: typeof initialState, action: ReturnType<typeof storeSessionInAudience>) => {
  //   if (action.payload) {
  //     state.sessionAudience = action.payload;
  //   } else {
  //     state.sessionAudience = {};
  //   }
  // });
});

export default audienceReducer;
