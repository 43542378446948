import { Tags } from '../../../../../common/tag/tag.types';
import { notFoundTagGroupName, TagGroupNames } from '../../comments-viewer.constants';

export const title: { [key in keyof Tags]: string } = {
  sentiments: 'Sentiment Score',
  sentiment: 'Sentiment',
  intents: 'Intents',
  keywords: 'Keywords',
  postTypes: 'Post Type',
  recommendations: 'Tags',
  amplifyEngaged: 'Engagement Type',
  isPostMention: 'Mentions',
  isIndirectComment: 'Comments',
  messageStatus: 'Message Status',
  customLabels: 'Custom Labels',
  assignedStatus: 'Assigned Status',
  sessionStartType: 'Session Start Types',
};

export const tagsOrderInDom = [
  TagGroupNames.AMPLIFY_ENGAGED,
  TagGroupNames.REPLIES,
  TagGroupNames.POST_TYPES,
  TagGroupNames.SENTIMENT,
  TagGroupNames.SENTIMENTS,
  TagGroupNames.SESSION_START_TYPE,
  TagGroupNames.CUSTOM_LABEL,
  TagGroupNames.MESSAGE_STATUS,
  TagGroupNames.KEYWORDS,
  TagGroupNames.RECOMMENDATIONS,
  TagGroupNames.INTENTS,
  notFoundTagGroupName,
];

const searchFilterConstants: SearchFilterConstantsType = {
  APPLY_SEARCH_FILTER_TAGS: 'APPLY_SEARCH_FILTER_TAGS',
  SET_SEARCH_FILTER_TAGS: 'SET_SEARCH_FILTER_TAGS',
  SET_INPUT_MODAL_VISIBILITY: 'SET_INPUT_MODAL_VISIBILITY',
  APPLY_CUSTOM_SEARCH_TEXT: 'APPLY_CUSTOM_SEARCH_TEXT',
};
type SearchFilterConstantsType = {
  APPLY_SEARCH_FILTER_TAGS: string;
  SET_SEARCH_FILTER_TAGS: string;
  SET_INPUT_MODAL_VISIBILITY: string;
  APPLY_CUSTOM_SEARCH_TEXT: string;
};
export default searchFilterConstants;
