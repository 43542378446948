import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import { storeSegmentsPaginationFromFilters, storeSegments, storeSegmentsBasePath, storeSegmentsPagination, storeSegmentUserCount } from '../actions';
import { PaginationValue } from '../components/type';
import { Segments } from '../segment.types';

export const initialState: {
  routeName: string;
  basePath: string;
  segments: Segments;
  userCounts: string;
  pagination?: PaginationValue;
} = {
  routeName: '',
  basePath: '',
  segments: {},
  userCounts: '',
};

const segmentsReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeSegmentsBasePath, (state: typeof initialState, action: ReturnType<typeof storeSegmentsBasePath>) => {
      state.basePath = action.payload;
    })
    .addCase(storeSegments, (state: typeof initialState, action: ReturnType<typeof storeSegments>) => {
      state.segments = action.payload;
    })
    .addCase(storeSegmentUserCount, (state: typeof initialState, action: ReturnType<typeof storeSegmentUserCount>) => {
      state.userCounts = action.payload;
    })
    .addCase(storeSegmentsPagination, (state: typeof initialState, action: ReturnType<typeof storeSegmentsPagination>) => {
      if (state.pagination) {
        state.pagination.pages = action.payload.pages || 0;
        state.pagination.total = action.payload.total || 0;
      } else {
        state.pagination = {
          page: action.payload.page || 1,
          size: action.payload.size || 50,
          total: action.payload.total || 0,
          pages: action.payload.pages || 0,
          sort: action.payload.sort || 'name',
        };
      }
    })
    .addCase(storeSegmentsPaginationFromFilters, (state: typeof initialState, action: ReturnType<typeof storeSegmentsPaginationFromFilters>) => {
      state.pagination = { ...(state.pagination || { total: 0, pages: 0 }), ...action.payload };
    });
});

export default segmentsReducer;
