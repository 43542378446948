import React from 'react';
import { Row, Col } from 'antd';
import styles from '../social-media-accounts.module.scss';
import { usePlatformBots, useBots } from '../../../../../hooks';
import CopyButton from '../../../../../common/components/copy/copy-button';
import { useIsBotworxAdminUser } from '../../../hooks';
import { getPageLink, getUserNameFromPlatformObj } from '../utils';

type BotInfoPopoverProps = {
  platformBotId: string | undefined;
};
/* eslint-disable max-lines-per-function */
const BotInfoPopover = ({ platformBotId }: BotInfoPopoverProps) => {
  const platforms = usePlatformBots() || {};
  const bots = useBots() || {};
  const botname = platformBotId && platforms?.[platformBotId]?.botId && bots?.[platforms[platformBotId].botId]?.name;
  const pageLink = platformBotId ? getPageLink(platformBotId, platforms?.[platformBotId]?.platform, getUserNameFromPlatformObj(platforms?.[platformBotId])) : '';
  const isBotworxAdmin = useIsBotworxAdminUser();
  return (
    <div className={styles.botContainer}>
      {isBotworxAdmin ? (
        <Row>
          <Col md={4}>
            <span className={styles.name}>Bot Name :</span>
          </Col>
          <Col md={18}>
            <div className={styles.platformBotIdCell}>
              <span className={styles.value}>{botname}</span>
              {botname && <CopyButton textToCopy={botname} />}
            </div>
          </Col>
          <Col md={4}>
            <span className={styles.name}>Bot Id :</span>
          </Col>
          <Col md={18}>
            <div className={styles.platformBotIdCell}>
              <span className={styles.value}>{platforms && platformBotId && platforms[platformBotId]?.botId}</span>
              {platforms && platformBotId && <CopyButton textToCopy={platforms[platformBotId]?.botId} />}
            </div>
          </Col>
          {platforms && platformBotId && platforms[platformBotId]?.description && (
            <>
              <Col md={4}>
                <span className={styles.name}>Description :</span>
              </Col>
              <Col md={18}>
                <div className={styles.platformBotIdCell}>
                  <span className={styles.value}>{platforms[platformBotId]?.description}</span>
                  {platforms && platformBotId && <CopyButton textToCopy={platforms[platformBotId]?.description} />}
                </div>
              </Col>
            </>
          )}
          <Col md={5}>
            <span className={styles.name}>Platform Bot Id:</span>
          </Col>
          <Col md={18}>
            <div className={styles.platformBotIdCell}>
              <span className={styles.value}>{platformBotId}</span>
              <CopyButton textToCopy={platformBotId} />
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          {platforms && platformBotId && platforms[platformBotId]?.description && (
            <>
              <Col md={4}>
                <span className={styles.name}>Description :</span>
              </Col>
              <Col md={18}>
                <div className={styles.platformBotIdCell}>
                  <span className={styles.value}>{platforms[platformBotId]?.description}</span>
                  {platforms && platformBotId && <CopyButton textToCopy={platforms[platformBotId]?.description} />}
                </div>
              </Col>
            </>
          )}
        </Row>
      )}
      <Row>
        {pageLink && (
          <>
            <Col md={4}>
              <span className={styles.name}>Page link:</span>
            </Col>
            <Col md={18}>
              <div className={styles.platformBotIdCell}>
                <span className={styles.value}>{pageLink}</span>
                <CopyButton textToCopy={pageLink} />
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default BotInfoPopover;
