/* eslint-disable @typescript-eslint/naming-convention */
import { postType } from '../../../common/entities/entities.constants';
import { engagementTypes } from '../../dashboard/components/comments-viewer/components/comment/comment.constants';
import { CommentTags, TagGroup, Tags } from './tag.types';

export const AmplifyGeneratedEngagement = 'Automated';

export const isIndirectComment: TagGroup = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  top_comments: {
    id: 'top_comments',
    label: 'Top Level',
    groupType: 'isIndirectComment',
    color: '#dfdeff',
  },
  replies: {
    id: 'replies',
    label: 'Reply',
    groupType: 'isIndirectComment',
    color: '#ffddd7',
  },
};

export const postTypes: TagGroup = {
  paid: {
    color: '#1297e066',
    id: 'paid',
    label: 'Paid',
    groupType: 'postTypes',
  },
  organic: {
    color: '#eecb5799',
    id: 'organic',
    label: 'Organic',
    groupType: 'postTypes',
  },
};
export const amplifyEngagedTagGroup: TagGroup = {
  external: {
    color: '#dfdeff',
    id: engagementTypes.EXTERNAL,
    label: 'External',
    groupType: 'amplifyEngaged',
  },
  manual: {
    color: '#eecb5799',
    id: engagementTypes.MANUAL,
    label: 'Manual',
    groupType: 'amplifyEngaged',
  },
  automated: {
    id: AmplifyGeneratedEngagement,
    label: AmplifyGeneratedEngagement,
    color: '#1297E019',
    groupType: 'amplifyEngaged',
  },
};
export const sentimentScore: TagGroup = {
  Neutral: {
    color: '#9090904c',
    label: 'Neutral',
    id: 'Neutral',
    groupType: 'sentiments',
  },
  'Very Positive': {
    color: '#34cbb433',
    label: 'Very Positive',
    id: 'Very Positive',
    groupType: 'sentiments',
  },
  'Moderately Positive': {
    color: '#34cbb433',
    label: 'Moderately Positive',
    id: 'Moderately Positive',
    groupType: 'sentiments',
  },
  'Moderately Negative': {
    color: '#ff9d9d33',
    label: 'Moderately Negative',
    id: 'Moderately Negative',
    groupType: 'sentiments',
  },
  'Very Negative': {
    color: '#ff9d9d33',
    label: 'Very Negative',
    id: 'Very Negative',
    groupType: 'sentiments',
  },
  'Extremely Negative': {
    color: '#ff9d9d33',
    label: 'Extremely Negative',
    id: 'Extremely Negative',
    groupType: 'sentiments',
  },
};
export const sentiment: TagGroup = {
  Neutral: {
    color: '#9090904c',
    label: 'Neutral',
    id: 'Neutral',
    groupType: 'sentiment',
  },
  Positive: {
    color: '#34cbb433',
    label: 'Positive',
    id: 'Positive',
    groupType: 'sentiment',
  },
  Negative: {
    color: '#ff9d9d33',
    label: 'Negative',
    id: 'Negative',
    groupType: 'sentiment',
  },
  Mixed: {
    color: '#eecb5799',
    label: 'Mixed',
    id: 'Mixed',
    groupType: 'sentiment',
  },
};
export const defaultCommentTags = (addEngagementTypeTags?: boolean): CommentTags => ({
  postTypes: addEngagementTypeTags ? postTypes : {},
  keywords: {},
  intents: {},
  sentiments: addEngagementTypeTags ? sentimentScore : {},
  sentiment: addEngagementTypeTags ? sentiment : {},
  recommendations: {},
  amplifyEngaged: addEngagementTypeTags ? amplifyEngagedTagGroup : {},
  isPostMention: {},
  isIndirectComment: addEngagementTypeTags ? isIndirectComment : {},
});

export const defaultTags = (): Tags => {
  return {
    ...defaultCommentTags(),
    customLabels: {},
    messageStatus: {},
    assignedStatus: {},
    sessionStartType: {},
  };
};

export const disabledTagToolTip = (label: string) =>
  `Currently, they are no ${label.toLowerCase()} ${label === postType.PAID || label === postType.ORGANIC ? 'posts' : 'comments'} available`;
