import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { setPageHealthDetails, setPageHealthDetailsLoading, setUserActiveTabPane, storeBotUsers, storeUsers } from '../actions';
import { UserListingTabs } from '../components/manage-users/constants';
import { ProfileManagementState } from '../type';

const initialState: ProfileManagementState = {
  segregatedUsers: {
    accountOwner: [],
    botOwner: [],
  },
  botUsers: {},
  usersActiveTabPane: UserListingTabs.accountOwners.value,
  isPageHealthDetailsLoading: false,
};

const profileManagementReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeUsers, (state: typeof initialState, action: ReturnType<typeof storeUsers>) => {
      state.segregatedUsers = action.payload.usersSegregated;
    })
    .addCase(storeBotUsers, (state: typeof initialState, action: ReturnType<typeof storeBotUsers>) => {
      const { botId, botUsers } = action.payload;
      state.botUsers = { [botId]: botUsers };
    })
    .addCase(setUserActiveTabPane, (state: typeof initialState, action: ReturnType<typeof setUserActiveTabPane>) => {
      state.usersActiveTabPane = action.payload;
    })
    .addCase(setPageHealthDetails, (state: typeof initialState, action: ReturnType<typeof setPageHealthDetails>) => {
      state.pageHealthDetails = action.payload;
    })
    .addCase(setPageHealthDetailsLoading, (state: typeof initialState, action: ReturnType<typeof setPageHealthDetailsLoading>) => {
      state.isPageHealthDetailsLoading = action.payload;
    });
});

export default profileManagementReducer;
