/* eslint-disable max-lines */
import moment from 'moment-timezone';
import {
  storeAccounts,
  storeCommentsAndCommentUsers,
  storeKeywordGroups,
  storePlatformBots,
  storePrivateReplyTags,
  storeTaggingGCs,
  storeTaggingGC,
  storeUserAccount,
  storeVariableForTheplatform,
  deleteLabel,
  storeLabelForTheplatform,
  addLabelForTheplatform,
  updateTaggingGCInUi,
  storeBots,
  updateBotWithNLP,
  storePlatformUsers,
  updatedBotConfig,
  storeSearchFilterTagsWithDate,
  removeSearchFilterTagsForDate,
  storePosts,
  storeSubscriptions,
  appendPosts,
} from '.';
// import commentsJSON from '../../../data/comment-viwer.json';
import { storeCampaigns } from '../../../pages/automation/actions';
import { transformCampaignsApiToUi } from '../../../pages/automation/transformer';
import { storePropertiesForUpdatingKeywordGroups, updateBotTrainingStatus } from '../../../pages/keyword-groups/actions';
import { getStartAndEndDate, sentimentLabel, sentimentRatingLabel } from '../../../pages/dashboard/components/comments-viewer/utils';
import { AsyncThunkDispatch, FetchCommentsApiResponse, History, RootState, Thunk } from '../../../types';
import { delete as restDelete, get, post, put } from '../../api-calls/api-client';
import {
  setConnectedToFb,
  setInitialDataFetchAttempted,
  setSelectedPlatformBot,
  setPageUIState,
  storeIsOverrideNlpEnabled,
  setFilter,
  setSelectedTagRange,
} from '../../page-ui-state/actions';
import { showErrorModal } from '../../utils';
import {
  Account,
  BotConfig,
  Comments,
  CommentTypeEnumsForIgPosts,
  CreateAuditLogAPIRequest,
  PlatformBot,
  PlatformUserUI,
  Post,
  Posts,
  PostsForIgComments,
  PostsForRequest,
  PrivateReplyApiTag,
  PrivateReplyTags,
  TaggingGC,
} from '../entities.types';
import transform from '../transformer';
import { selectPlatformBot } from '../utils';
import { storeStandardKeywords } from '../../../pages/landing/actions';
import { FetchStandardKeywordGroupsApiResponse, FetchPostsApiResponse } from '../../../types/api-calls';
import { indicateRecentCommentMessage, indicateSearchMessage } from '../../../pages/dashboard/components/comments-viewer/actions';
import { supportedPlatformsForGCs } from '../entities.constants';
import { isAccountsFetched } from '../../../common/page-ui-state/actions';
import { storeLabelToColorMap, updateLabelToColorMap } from '../../../pages/inbox/actions';
import { applyPostfilter, setIsPostFetching, setPostfilter } from '../../../pages/dashboard/components/comments-viewer/components/post-filter/actions';
import commentsViewerConstants, { commentTagFilterMap, commentTypes, TagGroupNames } from '../../../pages/dashboard/components/comments-viewer/comments-viewer.constants';
import { isMobileFun } from '../../../mobile/utils';
import { isCommentModeration, isMentionModeration, moderationModeFilter } from '../../../pages/dashboard/utils';
import { TimeFilterParams } from '../../../pages/common/time-filter/time-filter.types';
import { Comment, RawComment } from '../../../pages/dashboard/components/comments-viewer/components/comment/comment.types';
import { getCommentAutomatedActionConfig } from '../../../pages/dashboard/components/comments-viewer/components/comment/utils';
import { CommentsFilterKeysInsideFilter, maxLimits, pageFilterKeys, pageKeysForFilter } from '../../../pages/dashboard/constants';
import { getTimeZone } from '../../utils/time-zones';
import { customRange, timeFilterOptions } from '../../../pages/common/time-filter/time-filter.constants';
import { Subscription } from '../types/subscriptions.types';
import { transformSubscriptionsAPItoUI } from '../transformer/subscriptions';
import { pathsDontNeedAccountAccountSelectionAsArray } from '../../page-ui-state/constants';
import { storeBotConfig } from '../../../pages/settings/actions';
import { fetchVariableValues } from './segments.async-actions';
import { setSelectedPostsCountForSentimentAnalysis } from '../../../pages/sentiment-analysis/actions';

// import { commentsALL } from '../../../data/dashboard';
export { fetchPrivateReplyTagsForCurrentPlatformBot } from './private-reply.async-actions';

export const getUserAccounts = (done?: (accountId?: string, userId?: string, account?: Account) => void, selectAccount: string = '', history?: History): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    try {
      response = await get('accounts', authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      const accounts = transform.getAccountsApiToUi(response.data);
      dispatch(storeAccounts(accounts));
      dispatch(isAccountsFetched(true));
      // if (!state.pageUIState.routeParams?.accountId && !selectAccount && response?.data?.length > 1) {
      //   return;
      // }
      const account = transform.getUserAccountApiToUi(accounts, state.pageUIState.routeParams?.accountId, selectAccount || undefined);
      if (!account && window.location.pathname !== '/chooseAccount' && history && !pathsDontNeedAccountAccountSelectionAsArray.includes(window.location.pathname)) {
        history.replace('/chooseAccount');
      }
      dispatch(storeUserAccount(account));
      done && done(account?.id, state.auth.user?.id, account);
    }
  };
};

export const getBotsAndPlatformBots = (accountId: string, done?: () => void, chosenPlatformBot?: string, isInitialDataFetch: boolean = true): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    // const currentUser = state.auth.user;
    // const isSuperAdminOrBotworkAdmin = (currentUser && currentUser.roles && ['SuperAdmin', 'BotworxAdmin'].includes(currentUser.roles)) || false;

    const fetchSuccess = (data: any) => {
      const { platformBots, bots } = transform.getBotsAndPlatformBotsApiToUi(data, state.platformActionConfig.configData); // isSuperAdminOrBotworkAdmin, state.entities?.account
      dispatch(storePlatformBots(platformBots));
      dispatch(storeBots(bots));
      const platformBotFromUrl = state.pageUIState.routeParams?.platformBotId;
      const changeToPlatformBot = chosenPlatformBot || selectPlatformBot(platformBots, platformBotFromUrl || '', isMobileFun());
      dispatch(setSelectedPlatformBot(changeToPlatformBot));
      dispatch(setConnectedToFb(Object.keys(platformBots).length > 0 ? true : false));
      isInitialDataFetch && dispatch(setInitialDataFetchAttempted(true));

      done && done();
    };
    try {
      await dispatch(fetchBotPlatformBotForAccount(accountId, fetchSuccess));
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};

export const fetchBotPlatformBotForAccount = (accountId: string, done?: (data: any) => void) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    try {
      response = await get(`accounts/${accountId}/bots?fetchPlatformBots=true`, authToken as string);
      if (response?.data) {
        done?.(response.data);
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};

export const getBotSettings = (botId: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    try {
      response = await get(`bots/${botId}/settings`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      const { isOverrideNlpEnabled } = transform.getBotSettingsApiToUi(response.data);
      dispatch(storeIsOverrideNlpEnabled(isOverrideNlpEnabled));
    }
  };
};

export const getBotConfig = (botId: string): Thunk<BotConfig | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<BotConfig | null> => {
    let response: { data: BotConfig; status: number } | null = null;
    const state = getState();
    const authToken = state.auth.authToken;
    try {
      response = await get(`bots/${botId}/config`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
      dispatch(storeBotConfig(undefined));
    }
    if (response && response.status === 200) {
      dispatch(storeBotConfig(response.data));
      return response.data;
    }
    return null;
  };
};
export const getMediaUrlAsPerType = (data: any, callback: any, size?: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    const entities = state.entities;
    const platformBotId = state.pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId].botId : '';
    const payload = { name: data.name, data: data.data };
    try {
      const endPoint = `bots/${botId}/media/${data.mediaType}${size ? `?size=${size}` : ''}`;
      response = await post(endPoint, payload, authToken as string);
      callback(response.data && response.data.url);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};
export const updateBotConfig = (botId: string, updatedConfig: BotConfig, done?: () => void): Thunk<boolean> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<boolean> => {
    const url = `bots/${botId}/config`;
    const state = getState();
    const authToken = state.auth.authToken;

    try {
      await put(url, updatedConfig, authToken as string);
      dispatch(updatedBotConfig({ botId, config: updatedConfig }));
      done?.();
      return true;
    } catch (error) {
      showErrorModal(error, dispatch);
      return false;
    }
  };
};

/* eslint-disable max-lines-per-function */
export const fetchComments = (filterParams: TimeFilterParams, appliedTags?: any, appliedPosts?: any): Thunk<Comments | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<Comments | null> => {
    const { time, startDate: start, endDate: end } = filterParams;
    const state = getState();
    const authToken = state.auth.authToken;
    const entities = state.entities;
    const platformBotId = state.pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId]?.botId : '';
    const timezone = entities.account?.timezone;
    const moderationMode = state.pageUIState.moderationMode;
    const privateReplyTags = entities.privateReplyTags;
    const { startDate, endDate } = getStartAndEndDate(start, end, time, timezone);

    if (startDate && endDate) {
      const antiFilters: any[] = isCommentModeration(moderationMode) ? [moderationModeFilter] : [];
      const filters: any[] = [];
      Object.entries(appliedTags || {})
        .filter(([key, value]: [string, any]) => Object.keys(commentTagFilterMap).includes(key) && Object.keys(value || {}).length > 0)
        .forEach(([key, value]: [string, any]) => {
          const field = commentTagFilterMap[key] || '';
          let eq;
          switch (key) {
            case TagGroupNames.SENTIMENTS:
              const sentimentsList = Object.entries(sentimentLabel).reduce((acc: any, [label, value]: [string, string]) => ({ ...acc, [value]: label.toLowerCase() }), {});
              eq = Object.keys(value).map((item: string) => sentimentsList[item]);
              break;
            case TagGroupNames.SENTIMENT:
              const sentimentList = Object.entries(sentimentRatingLabel).reduce((acc: any, [label, value]: [string, string]) => ({ ...acc, [value]: label.toLowerCase() }), {});
              eq = Object.keys(value).map((item: string) => sentimentList[item]);
              break;
            case TagGroupNames.MENTIONS:
              eq = Object.keys(value);
              if (eq.length > 1) {
                return undefined;
              }
              if (eq[0] === '0') {
                eq = [false];
              } else if (eq[0] === '1') {
                eq = [true];
              }
              break;
            case TagGroupNames.REPLIES:
              eq = Object.keys(value);
              if (eq.length > 1) {
                return undefined;
              }
              if (eq[0] === commentTypes[0].id) {
                eq = undefined;
                antiFilters.push({ field, eq: [true] }); //add antifilters of reply true to get top comments alone
              } else if (eq[0] === commentTypes[1].id) {
                eq = [true];
              }
              break;
            default:
              eq = Object.keys(value);
          }
          if (eq) {
            filters.push({ field, eq });
          }
        });
      if (isMentionModeration(moderationMode)) {
        filters.push(moderationModeFilter);
      }
      if (appliedPosts && appliedPosts.length > 0) {
        const postIds = appliedPosts.map((post: Post) => post.id);
        filters.push({ field: 'postId', eq: postIds });
      }
      const payload: any = {
        startDate,
        endDate,
        limit: maxLimits.COMMENTS,
        ...(appliedTags?.customText
          ? {
              search: {
                term: appliedTags.customText,
              },
            }
          : {}),
        filters,
        antiFilters,
      };
      dispatch(setTagFilterRange({ startDate, endDate, time }));
      try {
        const response = await post(`/userComments?botId=${botId}`, payload, authToken as string);
        if (response && response.status === 200) {
          const data: FetchCommentsApiResponse =
            Object.entries(appliedTags?.amplifyEngaged).length > 0
              ? response!.data.filter(
                  (comment: RawComment) =>
                    Object.values(getCommentAutomatedActionConfig(comment.actions, Object.keys(appliedTags?.amplifyEngaged))).filter((value: any) => !!value).length,
                )
              : response!.data;
          if (response.data.length >= maxLimits.COMMENTS && data.length < maxLimits.COMMENTS) {
            dispatch(indicateRecentCommentMessage(false));
          }
          const { comments, commentUsers } = transform.fetchCommentsApiToUi(data, privateReplyTags || {});
          dispatch(storeCommentsAndCommentUsers({ comments, commentUsers }));
          dispatch(setPageUIState({ key: 'commentsLoaded', value: true }));
          dispatch(indicateRecentCommentMessage(!!(response.data && response.data.length >= maxLimits.COMMENTS)));
          dispatch(indicateSearchMessage(!!appliedTags?.customText));
          return comments;
        }
      } catch (error) {
        showErrorModal(error, dispatch);
      }
    }
    return null;
  };
};

/*for commentViewerModal*/
export const fetchParentAndChildrenCommentThread = (
  filterParams: TimeFilterParams,
  parentCommentId: string,
  callback: (parentComment: Comment | undefined, childComments: Comments) => void,
  doNotShowLoader?: boolean,
): Thunk => {
  return async (_dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { time, startDate: start, endDate: end } = filterParams;
    const state = getState();
    const authToken = state.auth.authToken;
    const entities = state.entities;
    const platformBotId = state.pageUIState.selectedPlatformBot || '';
    const { botId = '', platform = '' } = entities.platformBots ? entities.platformBots[platformBotId] : {};
    const timezone = entities.account?.timezone;
    const moderationMode = state.pageUIState.moderationMode;
    const privateReplyTags = entities.privateReplyTags || {};
    const { startDate, endDate } = getStartAndEndDate(start, end, time, timezone);

    if (startDate && endDate) {
      const antiFilters: any = isCommentModeration(moderationMode) ? [moderationModeFilter] : [];
      const filters: any = isMentionModeration(moderationMode) ? [moderationModeFilter] : [];
      filters.push({ field: 'parentId', eq: [parentCommentId] });
      const payload: any = {
        startDate,
        endDate,
        limit: maxLimits.COMMENTS,
        filters,
        antiFilters,
      };
      const [fetchChildCommentsPromise, fetchParentCommentsPromise] = await Promise.allSettled([
        post(`/userComments?botId=${botId}`, payload, authToken as string, doNotShowLoader),
        get(`/userComments/getUserComment?botId=${botId}&platformBotId=${platformBotId}&platform=${platform}&commentId=${parentCommentId}`, authToken as string, doNotShowLoader),
      ]);
      const childCommentResponse = (fetchChildCommentsPromise as any).value;
      const parentCommentResponse = (fetchParentCommentsPromise as any).value;
      const childComments: Comments = childCommentResponse?.status === 200 ? transform.fetchCommentsApiToUi(childCommentResponse.data, privateReplyTags).comments : {};
      const parentCommentList: Comments = parentCommentResponse?.status === 200 ? transform.fetchCommentsApiToUi([parentCommentResponse.data], privateReplyTags).comments : {};
      const parentComment = parentCommentList[parentCommentId];
      callback(parentComment, childComments);
    } else {
      callback(undefined, {});
    }
  };
};

export const setTagFilterRange = (filterParams: TimeFilterParams) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { entities, pageUIState } = getState();
    const { startDate, endDate, timeZone, time } = filterParams;
    const todayDate = moment.tz(getTimeZone(timeZone)).format('YYYYMMDD');
    const selectedTagRange = `${time}_${startDate}-${endDate}`;
    const previousTagRange = pageUIState?.selectedTagRange || '';
    const previousTagRangeTime = previousTagRange.split('_')[0];
    dispatch(setSelectedTagRange(selectedTagRange));
    if (previousTagRangeTime === timeFilterOptions[0].value) {
      //clear and refetch filter Tags always for today date
      dispatch(removeSearchFilterTagsForDate(previousTagRange));
      dispatch(fetchCommentFilterTags(filterParams));
    } else if (previousTagRangeTime === customRange && (previousTagRange !== selectedTagRange || todayDate === endDate)) {
      //clear and refetch filter Tags for custom Date if endDate contains today date or previous custom Range is not equal to newer custom Range
      dispatch(removeSearchFilterTagsForDate(previousTagRange));
      dispatch(fetchCommentFilterTags(filterParams));
    } else if (!entities.tags?.[selectedTagRange]) {
      //fetch FilterTags if no tags exist for current tag range
      dispatch(fetchCommentFilterTags(filterParams));
    }
  };
};

export const fetchCommentFilterTags = (filterParams: TimeFilterParams): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { startDate, endDate, time } = filterParams;
    const state = getState();
    const authToken = state.auth.authToken;
    const entities = state.entities;
    const privateReplyTags = entities.privateReplyTags;
    const pageUIState = state.pageUIState;
    const platformBotId = pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId]?.botId : '';
    const { id: accountId = '' } = entities.account || {};
    const moderationMode = pageUIState.moderationMode;
    if (startDate && endDate) {
      const aggregations: any[] = Object.values(commentTagFilterMap).map((aggregationField: string) => {
        return {
          aggregationField,
          bucketLimit: maxLimits.TAGFILTER,
        };
      });
      const payload: any = {
        accountId,
        botId,
        startDate,
        endDate,
        filters: isMentionModeration(moderationMode) ? [moderationModeFilter] : [],
        antiFilters: isCommentModeration(moderationMode) ? [moderationModeFilter] : [],
        aggregations,
      };
      try {
        const response = await post(`/userComments/aggregations`, payload, authToken as string);
        if (response && response.status === 200) {
          const commentTags = transform.transformCommentFilterTagsApiToUI(response.data, privateReplyTags || {});
          const dateRange = `${time}_${startDate}-${endDate}`;
          dispatch(storeSearchFilterTagsWithDate({ commentTags, dateRange }));
        }
      } catch (error) {
        showErrorModal(error, dispatch);
      }
    }
  };
};

export const fetchPosts = (
  posts: PostsForRequest[],
  appliedPosts: any[],
  platform: string,
  doNotShowLoader: boolean = false,
  showLoaderInPostFilterModal: boolean = false,
  postsWithSampleCommentId?: { [key: string]: string },
  moderationType?: string,
): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const authToken = state.auth.authToken;
    const entities = state.entities;
    const moderationMode = moderationType || state.pageUIState.moderationMode;
    const isMention = isMentionModeration(moderationMode);
    // const selectedPosts = state?.commentsViewer?.postFilter.selectedPosts;
    // const setSelectedPosts = (selectedPosts: Post[]) => {
    //   dispatch(setPostfilter(commentsViewerConstants.COMMENTS_VIEWER)(selectedPosts));
    // };
    const updatedAppliedPosts = appliedPosts.length > 0 ? appliedPosts : state.commentsViewer.postFilter.appliedPosts;
    const setAndApplyPosts = (posts: Post[]) => {
      dispatch(setPostfilter(commentsViewerConstants.COMMENTS_VIEWER)(posts));
      dispatch(applyPostfilter(commentsViewerConstants.COMMENTS_VIEWER)(posts));
      //remove posts which are not present in current date range from filter Selector
      dispatch(
        setFilter(
          pageKeysForFilter.COMMENTS,
          pageFilterKeys.FILTERS,
          {
            [CommentsFilterKeysInsideFilter.POST]: posts.map((post: Post) => {
              return {
                id: post.id,
              };
            }),
          },
          false,
        ),
      );
    };
    const platformBotId = state.pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId].botId : '';
    const payload: any = { botId, platform, platformBotId, fields: 'permalink_url' };

    const postsPayload: PostsForIgComments[] = posts.map((post: PostsForRequest) => ({
      id: post.id,
      type: post.postType,
      commentType: isMention ? CommentTypeEnumsForIgPosts.MENTIONS : CommentTypeEnumsForIgPosts.COMMENTS,
      ...(isMention ? { sampleCommentId: postsWithSampleCommentId?.[post.id] } : {}),
    }));

    let newAllPosts: Posts = {};

    const batchSize = 100;
    const totalBatches = Math.ceil(postsPayload.length / batchSize);

    dispatch(storePosts({}));
    dispatch(setSelectedPostsCountForSentimentAnalysis(postsPayload.length));
    showLoaderInPostFilterModal && dispatch(setIsPostFetching(commentsViewerConstants.COMMENTS_VIEWER)(true));
    for (let i = 0; i < totalBatches; ++i) {
      const start = i * batchSize;
      let transformedBatchPosts: Posts = {};
      const end = Math.min((i + 1) * batchSize, postsPayload.length);
      const batchPosts = await fetchBatchPosts({ ...payload, posts: postsPayload.slice(start, end) }, authToken as string, doNotShowLoader, (error: any) => {
        showErrorModal(error, dispatch);
      });
      transformedBatchPosts = transform.convertPostsToUI(batchPosts, posts || []);
      dispatch(appendPosts(transformedBatchPosts));
      newAllPosts = { ...newAllPosts, ...transformedBatchPosts };
    }
    showLoaderInPostFilterModal && dispatch(setIsPostFetching(commentsViewerConstants.COMMENTS_VIEWER)(false));
    const newAppliedPosts = updatedAppliedPosts.filter((post: Post) => newAllPosts[post.id]).map((post: Post) => newAllPosts[post.id]);
    setAndApplyPosts(newAppliedPosts);
  };
};

const fetchBatchPosts = async (payload: any, authToken: string, doNotShowLoader: boolean = false, onError: (error: any) => void) => {
  let response: { data: FetchPostsApiResponse; status: number } | null = null;
  try {
    if (payload?.posts?.length) {
      response = await post(`/privateReplies/postDetails`, payload, authToken as string, doNotShowLoader);
    }
  } catch (error) {
    onError(error);
  }
  if (response && response.status === 200) {
    const data: FetchPostsApiResponse = response!.data;
    return data;
  }
  return [];
};

export const fetchKeywordGroups = () => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    const entities = state.entities;
    const platformBotId = state.pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId].botId : '';
    try {
      response = await get(`/botNLP?botId=${botId}&includeExtraFields=trainedDataEntities,entities`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      const { keywordGroups, entitiesId, lastUpdatedDate, botTrainingInfo } = transform.fetchKeywordGroupsApiToUi(response.data);
      dispatch(storeKeywordGroups(keywordGroups));
      dispatch(updateBotWithNLP(response.data));
      dispatch(storePropertiesForUpdatingKeywordGroups({ entitiesId, lastUpdatedDate }));
      dispatch(updateBotTrainingStatus(botTrainingInfo));
      dispatch(fetchStandardKeywordGroups());
      dispatch(fetchAssociatedCampaignsForKeywordGroups());
    }
  };
};
export const fetchStandardKeywordGroups = (forLandingPage?: boolean) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    const account = state.entities.account;
    const accountId = account?.id;
    try {
      response = await get(`/botNLP/standardKeywordGroups?accountId=${accountId}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      if (forLandingPage) {
        handleLandingPageKeywordGroups(account!, response.data, dispatch);
        return;
      }
      const keywordGroups = getState().entities.keywordGroups;
      if (keywordGroups) {
        const { updatedKeywordGroups } = transform.fetchStandardKeywordGroupsApiToUi(response.data, keywordGroups);
        dispatch(storeKeywordGroups(updatedKeywordGroups));
      }
    }
  };
};
export const handleLandingPageKeywordGroups = (account: Account, standardKeywords: FetchStandardKeywordGroupsApiResponse, dispatch: AsyncThunkDispatch) => {
  const configuredKeywords = account.config?.amplifyLiteUIConfig?.configuredKeywords || {};
  const transformedStandardKeywords = transform.transformKeywordsToUI(standardKeywords);
  const { keywordsInConfig } = transform.tranformKeywordsForAccountApiToUi(transformedStandardKeywords, transform.transformKeywordsToUI(configuredKeywords));
  dispatch(storeKeywordGroups(keywordsInConfig));
  dispatch(storeStandardKeywords(transformedStandardKeywords));
};

export const fetchAssociatedCampaignsForKeywordGroups = () => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    const entities = state.entities;
    const platformBotId = state.pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId].botId : '';
    const platform = entities.platformBots ? entities.platformBots[platformBotId].platform : '';
    try {
      response = await get(`/privateReplies?botId=${botId}&platform=${platform}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      const keywordGroups = getState().entities.keywordGroups;
      if (keywordGroups) {
        const { updatedKeywordGroups } = transform.fetchAssociatedCampaignsForKeywordGroupsApiToUi(response.data, keywordGroups);
        const { taggingGCs } = transform.fetchTaggingGCsApiToUi(response.data);
        dispatch(storeKeywordGroups(updatedKeywordGroups));
        dispatch(storeTaggingGCs(taggingGCs));
      }
    }
  };
};

export const fetchVariablesForABot = (variableType?: string, fetchVarValues?: boolean): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth, entities, pageUIState } = getState();
    const selectedPlatformBotId = pageUIState.selectedPlatformBot;
    const platformBot: { [key: string]: PlatformBot } = entities?.platformBots || {};
    let botId = '';
    let response = null;
    const queryString = variableType ? `&variableType=${variableType}` : '';

    if (selectedPlatformBotId) {
      botId = platformBot[selectedPlatformBotId].botId;
      const url = `variables?botId=${botId}${queryString}`;
      try {
        response = await get(url, auth.authToken as string);
      } catch (error) {
        showErrorModal(error, dispatch);
      } finally {
        if (response && response.data) {
          //TODO successful status codes condition
          const { variables } = transform.getVariablesApiToUi(response.data);
          dispatch(storeVariableForTheplatform(variables));
          fetchVarValues && dispatch(fetchVariableValues(botId));
        }
      }
    }
  };
};

export const fetchCustomLabelsForABot = (fetchGlobalLabels: boolean = false): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth, entities, pageUIState } = getState();
    const selectedPlatformBotId = pageUIState.selectedPlatformBot;
    const platformBot: { [key: string]: PlatformBot } = entities?.platformBots || {};
    let botId = '';
    let response = null;

    if (selectedPlatformBotId) {
      botId = platformBot[selectedPlatformBotId].botId;
      const url = `labels?botId=${botId}${fetchGlobalLabels ? `&fetchGlobalLabels=true` : ''}`;
      try {
        response = await get(url, auth.authToken as string);
      } catch (error) {
        showErrorModal(error, dispatch);
      } finally {
        if (response && response.data) {
          //TODO successful status codes condition
          const { labels, labelToColorMap } = transform.getCustomLabelsApiToUi(response.data);

          dispatch(storeLabelForTheplatform(labels));
          dispatch(storeLabelToColorMap(labelToColorMap));
        }
      }
    }
  };
};

export const fetchTaggingGCs = () => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    const entities = state.entities;
    const supportedPlatformsInput =
      state.platformActionConfig && state.platformActionConfig.supportedPlatforms ? state.platformActionConfig.supportedPlatforms : supportedPlatformsForGCs;
    const platformBotId = state.pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId].botId : '';
    try {
      response = await get(`/privateReplies?botId=${botId}&platform=${Array.from(supportedPlatformsInput).join(',')}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      const { taggingGCs } = transform.fetchTaggingGCsApiToUi(response.data);
      dispatch(storeTaggingGCs(taggingGCs));
      dispatch(storeCampaigns({ campaigns: transformCampaignsApiToUi(response.data) }));
    }
  };
};
export const fetchTaggingGC = (campaignId: string, onDone?: (res?: any) => void, includeDeleted: boolean = false) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    try {
      response = await get(`/privateReplies/${campaignId}${includeDeleted ? '?includeDeleted=true' : ''}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      const { taggingGCs } = transform.fetchTaggingGCsApiToUi([response.data]);
      if (!includeDeleted) {
        dispatch(storeTaggingGC(Object.values(taggingGCs)[0]));
      }
      onDone?.(response?.data);
    }
  };
};

export const updateRecommendationInBackend = (recommendation: TaggingGC['rawGC'], done?: () => void) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    const payload = recommendation;
    try {
      response = await put(`privateReplies`, payload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
      done && done();
    }
    if (response && response.status === 204) {
      const { taggingGCs } = transform.fetchTaggingGCsApiToUi([{ ...payload!, lastUpdatedDate: response.headers['x-response-time'] }]);
      dispatch(updateTaggingGCInUi(Object.values(taggingGCs)[0]));
      done && done();
    }
  };
};
export const deletePlatformBot = (id: string, done?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { authToken } = getState().auth;
    let response = null;
    try {
      response = await restDelete(`platformBots/${id}?isAmplifyLite=${true}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 204) {
      done && done();
    }
  };
};
export const deleteTaggingGC = (id: string, done?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { authToken } = getState().auth;
    let response = null;
    try {
      response = await restDelete(`privateReplies/${id}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 204) {
      dispatch(fetchTaggingGCs());
      done && done();
    }
  };
};
export const deleteTag = (id: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { authToken } = getState().auth;
    try {
      await restDelete(`privateReplyTags/${id}`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};
export const fetchPrivateReplyTags = (done?: (privateReplyTags: PrivateReplyTags) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth, entities, pageUIState } = getState();
    const { authToken } = auth;
    const platformBotId = pageUIState.selectedPlatformBot;
    const platform = platformBotId && entities.platformBots && entities.platformBots[platformBotId].platform;
    let response = null;
    try {
      response = await get(`privateReplyTags?platformBotId=${platformBotId}&platform=${platform}&includeDeleted=false`, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      const { privateReplyTags } = transform.fetchPrivateReplyTagsApiToUi(response.data);
      dispatch(storePrivateReplyTags(privateReplyTags));
      done && done(privateReplyTags);
    }
  };
};
export const createTaggingGCAndTag = (gc: { [key: string]: unknown }, tag: PrivateReplyApiTag, done?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth, entities, pageUIState } = getState();
    const { authToken } = auth;
    const platformBotId = pageUIState.selectedPlatformBot;
    const platform = platformBotId && entities.platformBots && entities.platformBots[platformBotId].platform;
    delete tag.id;
    const gcPayload = { ...gc, platformBotId, platform };
    const tagsPayload = { ...tag, platformBotId, platform };
    try {
      post(`privateReplies`, gcPayload, authToken as string).then((gcResponse: any) => {
        if (gcResponse?.status === 200) {
          post(`privateReplyTags`, tagsPayload, authToken as string).then((tagResponse?: any) => {
            tagResponse?.status === 200 && done?.();
          });
        }
      });
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};
export const updateTaggingGCAndTag = (gc: { [key: string]: unknown }, tag: PrivateReplyApiTag, done?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const { auth, entities, pageUIState } = getState();
    const { authToken } = auth;
    const platformBotId = pageUIState.selectedPlatformBot;
    const platform = platformBotId && entities.platformBots && entities.platformBots[platformBotId].platform;
    const gcPayload = { ...gc, platformBotId, platform };
    const tagsPayload = { ...tag, platformBotId, platform };
    try {
      put(`privateReplies`, gcPayload, authToken as string)?.then((gcResponse: any) => {
        if (gcResponse?.status === 204) {
          put(`privateReplyTags`, tagsPayload, authToken as string).then((tagResponse?: any) => {
            tagResponse?.status === 200 && done?.();
          });
        }
      });
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};
export const getMediaURL = (data: any, callback: any): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response = null;
    const state = getState();
    const authToken = state.auth.authToken;
    const entities = state.entities;
    const platformBotId = state.pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId].botId : '';
    const payload = {
      data,
      botId,
    };
    try {
      response = await post(`images`, payload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      callback(response.data && response.data.imageURL);
    }
  };
};
export const fetchPlatformUser = (platformUserId: string, platformBotId: string, platform: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response;
    const state = getState();
    const authToken = state.auth.authToken;
    const entities = state.entities;
    const botId = entities.platformBots ? entities.platformBots[platformBotId]?.botId : '';

    try {
      response = await get(
        `platformUsers?platformUserId=${encodeURIComponent(platformUserId)}&botId=${botId}&platformBotId=${platformBotId}&platform=${platform}`,
        authToken as string,
      );
      if (response && response?.data) {
        const users = transform.convertPlatformUsersToUI([response?.data]);
        dispatch(storePlatformUsers(users));
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};
export const updatePlatformUser = (platformUser: PlatformUserUI, updatedValues: any, done?: (userId: string, updatedPlatformUser?: any) => void): Thunk => {
  const { firstName, middlename, lastName, gender, city, country } = updatedValues;
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response;
    const state = getState();
    const authToken = state.auth.authToken;
    const entities = state.entities;
    const platformBotId = state.pageUIState.selectedPlatformBot || '';
    const botId = entities.platformBots ? entities.platformBots[platformBotId].botId : '';
    const payload = {
      ...platformUser.rawUser,
      firstName,
      middlename,
      lastName,
      gender,
      location: { ...platformUser?.rawUser?.location, city, country },
    };

    try {
      response = await put(`platformUsers?botId=${botId}`, payload, authToken as string);
      if (response && response?.status === 204) {
        const users = transform.convertPlatformUsersToUI([{ ...payload, lastUpdatedDate: response.headers['x-response-time'] }]);
        dispatch(storePlatformUsers(users));
        done && done(platformUser.rawUser.platformUserId, Object.values(users || {})?.[0]);
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};

export const createCustomLabel = (
  labelName: string,
  botId: string,
  description?: string,
  color?: string,
  allKeywords: string = '',
  done?: (id: string, label: string) => void,
): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response;
    const state = getState();
    const authToken = state.auth.authToken;

    const url = `labels`;
    const conditions = transform.getKeywordsUiToApi(allKeywords) || {};
    const payload = {
      name: labelName,
      botId,
      description,
      ...(color && { metadata: { color } }),
      ...(conditions && Object.keys(conditions)?.length && { conditions }),
    };
    try {
      response = await post(url, payload, authToken as string);
      if (response && response.status === 200) {
        const { labels, labelToColorMap } = transform.getCustomLabelsApiToUi([response.data]);
        dispatch(addLabelForTheplatform(labels));
        dispatch(updateLabelToColorMap(labelToColorMap));
        done && done(response?.data?.id, labelName);
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};

export const editCustomLabel = (
  id: string,
  color?: string,
  description?: string,
  allKeywords: string = '',
  done?: (id: string, name: string, isUpdate: boolean) => void,
): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response;
    const state = getState();
    const authToken = state.auth.authToken;

    const label = state.entities.customLabels[id];
    const conditions = transform.getKeywordsUiToApi(allKeywords) || {};

    const url = `labels`;
    const body = {
      ...label,
      description,
      ...(color && { metadata: { color } }),
      ...(conditions && Object.keys(conditions)?.length && { conditions }),
    };
    delete body['keywords'];
    try {
      response = await put(url, body, authToken as string);
      const newVariable = { ...body, lastUpdatedDate: response.headers['x-response-time'] };

      const { labels, labelToColorMap } = transform.getCustomLabelsApiToUi([newVariable]);
      dispatch(addLabelForTheplatform(labels));
      dispatch(updateLabelToColorMap(labelToColorMap));
      done && done(id, label, true);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};

export const deleteCustomLabel = (labelId: string, done?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = `labels/${labelId}`;
    const state = getState();
    const authToken = state.auth.authToken;

    try {
      await restDelete(url, authToken as string);
      dispatch(deleteLabel(labelId));
      done?.();
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};

export const updatePlatformBotProfilePictureUrl = (profilePic: string, name?: string | any): Thunk => {
  return async (dispatch: AsyncThunkDispatch): Promise<void> => {
    const fetchSuccess = (data: PlatformBot) => {
      data.profilePictureUrl = profilePic;
      name && (data.name = name);
      dispatch(updatePlatformBot(data));
    };
    try {
      await dispatch(fetchPlatformBot(fetchSuccess));
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};

export const fetchPlatformBot = (done?: (data: PlatformBot) => void) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    let response: { data: PlatformBot; status: number } | null = null;
    const state = getState();
    const authToken = state.auth.authToken;
    const platformBotId = state.pageUIState.selectedPlatformBot || '';
    const platform = state.entities.platformBots ? state.entities.platformBots[platformBotId]?.platform : '';
    try {
      response = await get(`/platformBots/${platformBotId}?platform=${platform}`, authToken as string);
      if (response && response.status === 200) {
        response?.data && done?.(response.data);
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};

export const updatePlatformBot = (payload: PlatformBot, dontFetchBotsForAccount: boolean = false): Thunk<boolean> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<boolean> => {
    let response: any = null;
    const state = getState();
    const authToken = state.auth?.authToken;
    const account = state.entities?.account;
    const accountId = account?.id;
    try {
      response = await put(`platformBots`, payload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 204 && !dontFetchBotsForAccount) {
      accountId && dispatch(getBotsAndPlatformBots(accountId));
    }
    return false;
  };
};

export const createAuditLogs = (payload: CreateAuditLogAPIRequest): Thunk<any> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<any> => {
    let response: any = null;
    const state = getState();
    const authToken = state.auth.authToken;
    try {
      response = await post(`auditLogs`, payload, authToken as string);
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      return response.data;
    }
    return null;
  };
};

export const fetchAuditLogs = (
  botId: string | undefined,
  accountId: string | undefined,
  entityType: string,
  requestType: string = 'create',
  entityId?: string,
  forAllUsers: boolean = false,
): Thunk<any> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<any> => {
    let response: any = null;
    const state = getState();
    const authToken = state.auth.authToken;
    try {
      response = await get(
        `auditLogs?entityType=${entityType}${botId ? `&botId=${botId}` : ''}${accountId ? `&accountId=${accountId}` : ''}&requestType=${requestType}${
          entityId ? `&entityId=${entityId}` : ''
        }${forAllUsers ? '&fetchForAllUsers=true' : ''}`,
        authToken as string,
      );
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      return response.data;
    }
    return null;
  };
};

export const fetchSubscriptions = (botId: string) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<Subscription[] | null> => {
    let response: { data: Subscription[]; status: number } | null = null;
    const state = getState();
    const authToken = state.auth.authToken;
    try {
      response = await get(`/subscriptions?botId=${botId}`, authToken as string);
      if (response && response.status === 200 && response.data) {
        dispatch(storeSubscriptions(transformSubscriptionsAPItoUI(response.data)));
        return response.data;
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    }
    return null;
  };
};
